import React from 'react';
import { Food } from '../interfaces/Food';

interface Props {
  foods: Food[];
  handleInputUpdate?: (newList: Food[]) => void;
}

export default function AdminFoodsInput(props: Props) {
  return (
    <div>
      {props.foods.map((food, index) => {
        const foodInfo = [];
        for (let key in food) {
          // add the JSX element to the foodInfo array
          foodInfo.push(
            <div key={key} className="flex flex-col">
              <label>{key}</label>
              <input title={key} type="text" defaultValue={food[key]} />
            </div>
          );
        }
        // return the array of JSX elements outside the loop
        return foodInfo;
      })}
    </div>
  );
}
