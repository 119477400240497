import React from 'react';
import { Food } from '../interfaces/Food';
import AdminFoodsInput from './AdminFoodsInput';

interface Props {
  foodValue?: string | Food;
  labelName: string;
  textarea?: boolean;
  list?: Food[];
  handleInputUpdate: (updatedValues: { [key: string]: any } | Food[]) => void;
}

export default function AdminLabelInput(props: Props) {
  const renderInput = () => {
    const inputList = [];
    if (props.foodValue instanceof Object) {
      for (let key in props.foodValue) {
        inputList.push({
          key: key,
          value: props.foodValue[key],
        });
      }
    } else if (props.textarea) {
      inputList.push({
        key: props.labelName,
        value: props.foodValue as string,
        type: 'textarea',
        rows: 3,
      });
    } else if (props.list) {
      inputList.push({
        key: props.labelName,
        value: props.list,
        type: 'list',
      });
    } else {
      inputList.push({
        key: props.labelName,
        value: props.foodValue as string,
        type: 'input',
      });
    }

    return inputList.map((input) => (
      <div key={input.key} className="flex flex-col text-slate-500">
        <label className="text-slate-700">{input.key}</label>
        {input.type === 'textarea' ? (
          <textarea
            title={input.key}
            name=""
            id=""
            rows={input.rows}
            defaultValue={input.value as string}
            className="text-slate-500 text-xl"
            onBlur={(e) => {
              console.log(e.target.value);
              props.handleInputUpdate({
                [input.key]: e.target.value,
              });
            }}
          />
        ) : input.type === 'list' ? (
          <AdminFoodsInput
            foods={input.value as Food[]}
            handleInputUpdate={(newList: any) => {
              console.log(newList);
              props.handleInputUpdate({ [input.key]: newList });
            }}
          />
        ) : (
          <input
            title={input.key}
            type="text"
            name=""
            id=""
            defaultValue={input.value}
            className="text-slate-500 text-xl"
            onBlur={(e) => {
              console.log(e.target.value);
              props.handleInputUpdate({
                [input.key]: e.target.value,
              });
            }}
          />
        )}
      </div>
    ));
  };

  return <div className="flex flex-col">{renderInput()}</div>;
}
