import { Item } from '../interfaces/Item';
import { convertCentsToDollar } from '../utils/Converter';

interface Props {
  orderNumber: string;
  item: Item;
  tagColor: string;
}

export const OrderCard = (props: Props) => {
  return (
    <div className="flex flex-col bg-white rounded-lg shadow-md p-4 mt-4 w-full lg:w-96 border border-gray-200">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-medium text-gray-800">{props.item.name}</h2>
        <span className="text-gray-600 text-sm">{`$${convertCentsToDollar(
          props.item.price.toString()
        )}`}</span>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center">
          <span className="text-gray-600 text-sm">Quantity:</span>
          <span className="text-lg font-medium text-gray-800">
            {props.item.quantity}
          </span>
        </div>
        {Array.isArray(props.item.customization) &&
          props.item.customization.length > 0 && (
            <div>
              <span className="text-gray-600 text-sm">Customizations:</span>
              <ul className="list-disc list-inside">
                {props.item.customization.map((customization, index) => (
                  <li key={index} className="text-sm text-gray-800">
                    {`${customization.name} ${customization.quantity}x`}
                  </li>
                ))}
              </ul>
            </div>
          )}

        <div className="flex justify-between items-center">
          <span className="text-gray-600 text-sm">Order number:</span>
          <span
            className={`text-lg font-medium text-white rounded-full py-1 px-3 ${props.tagColor}`}
          >
            {props.orderNumber}
          </span>
        </div>
      </div>
    </div>
  );
};
