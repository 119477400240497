import type { Food } from '../interfaces/Food';

interface Props {
  option: Food[];
  name: string;
  food?: Food;
  setFood?: Function;
}

export default function DropdownOptions(props: Props) {
  // cents to dollar
  const convertCentsToDollar = (amount: string): number => {
    return parseInt(amount, 10) / 100;
  };

  return (
    <div className="collapse collapse-arrow text-slate-700 w-full rounded-lg shadow font-semibold text-lg">
      <input type="checkbox" className="peer" />
      <div className="collapse-title bg-white text-xl">
        {props.name.toUpperCase()}
      </div>
      <div className="collapse-content bg-white flex flex-col justify-between px-6 space-y-3">
        {props.option &&
          props.option.map((item: Food, index: any) => {
            return (
              <div
                className="flex flex-row justify-between items-center w-full"
                key={index}
              >
                <p>{item.name?.toUpperCase()}</p>
                <div className="flex flex-row space-x-4">
                  <p className="text-sm">
                    {item.price !== '0'
                      ? '$' + convertCentsToDollar(item.price!).toFixed(2)
                      : ''}
                  </p>
                  <input
                    type="checkbox"
                    className="checkbox bg-gray-100"
                    onChange={(event) => {
                      if (event.target.checked) {
                        const _tmpFood = {
                          ...props.food,
                          customization: [...props.food?.customization!, item],
                        };
                        props.setFood!(_tmpFood);
                      } else {
                        const _tmpFood = {
                          ...props.food,
                          customization: props.food?.customization?.filter(
                            (food) => food.name !== item.name
                          ),
                        };
                        props.setFood!(_tmpFood);
                      }
                    }}
                    checked={
                      props.food?.customization?.find(
                        (_item) => _item.name === item.name
                      )
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
