import { motion } from 'framer-motion';
import type { Food } from '../interfaces/Food';

interface Props {
  food: Food;
  index: number;
}

export default function PaidItem(props: Props) {
  const DELAY = 0.2;

  // cents to dollar
  const convertCentsToDollar = (amount: string): number => {
    return parseInt(amount, 10) / 100;
  };

  return (
    <div>
      <div>
        <motion.div className="card-body flex-row justify-between items-center">
          {/* middle name and price */}
          <motion.div
            initial={{ x: -100 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.75, delay: props.index * DELAY }}
            className="flex flex-col w-[80%]"
          >
            {/* name */}
            <p
              className={
                'font-medium text-[#6c3b17] ' +
                (props.food.name!.length > 60 ? 'text-[0.3rem]' : 'text-lg')
              }
            >
              {props.food.name!}
            </p>

            {/* price */}
          </motion.div>

          {/* right button */}
          <motion.div
            initial={{ x: 100 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.75, delay: props.index * DELAY }}
            className="flex justify-end w-[70%]"
          >
            {/* left, image */}
            <motion.div className="flex flex-col justify-center items-center w-[50%]">
              <img
                src={props.food.imgURL!}
                alt="food"
                className="rounded-lg aspect-auto"
              />
            </motion.div>
            {/* no button */}
          </motion.div>
        </motion.div>
      </div>
      {/* <hr className="border-slate-400" /> */}
    </div>
  );
}
