import React from 'react';

export default function Loading() {
  return (
    <div className="h-screen w-screen z-50">
      <div className="flex h-full w-full justify-center pt-[25%]">
        <div className="flex justify-center items-start pt-9">
          <div
            className="animate-ping bg-primary w-12 h-12 rounded-full opacity-20"
            role="status"
          ></div>
        </div>
      </div>
    </div>
  );
}
