import { useEffect, useState } from 'react';
import AdminEditCard from '../components/AdminEditCard';
import AdminItem from '../components/AdminItem';
import AdminSearchBar from '../components/AdminSearchBar';
import { Food } from '../interfaces/Food';

export default function Admin() {
  // toggle for menu card
  const [editFood, setEditFood] = useState<Food>({ id: '-1' });

  // create state for password check
  const [adp, setAdp] = useState<string>('');

  // create a state for the data
  const [data, setData] = useState<Food[]>([]);
  const [filteredData, setFilteredData] = useState<Food[]>([]);
  const [query, setQuery] = useState<string>('');

  // fetch data from the api using fetch api
  useEffect(() => {
    // if it doesn't, then fetch the data from the api
    const fetchData = async () => {
      // fetch data from the api
      const res = await fetch(
        'https://r1isfpy1tg.execute-api.us-east-1.amazonaws.com/prod/menu'
      );

      // parse the data
      const _data: Food[] = await res.json();

      // set the data
      setData(_data);
    };

    // check if the data is in the local storage
    const localData = localStorage.getItem('foodItems');
    // time stamp in localstorage
    const localTimeStamp = localStorage.getItem('timeStamp')
      ? localStorage.getItem('timeStamp')
      : () => {
          localStorage.setItem('timeStamp', Date.now().toString());
          return localStorage.getItem('timeStamp');
        };

    // if it is, then set the data
    if (localData && Date.now() - Number(localTimeStamp) < 10000) {
      // 86400000 = 1 day, 3600000 = 1 hour, 60000 = 1 minute, 1000 = 1 second
      setData(JSON.parse(localStorage.getItem('foodItems')!));
    } else {
      // call the function
      fetchData();
    }
  }, []);

  useEffect(() => {
    data.length > 0 && localStorage.setItem('foodItems', JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    const filtered = data.filter((food: Food) => {
      return food.name!.toLowerCase().includes(query.toLowerCase());
    });

    // sort the data ensuring the first letter is matching the query
    filtered.sort((a: Food, b: Food) => {
      if (a.name!.toLowerCase().startsWith(query.toLowerCase())) {
        return -1;
      } else {
        return 1;
      }
    });

    setFilteredData(filtered);
  }, [query, data]);

  useEffect(() => {
    // if not id -1,
    if (editFood.id !== '-1') {
      // then get localStorage foodItem
      const foodItems = JSON.parse(localStorage.getItem('foodItems')!);

      // find the index of the food item
      const index = foodItems.findIndex(
        (food: Food) => food.id === editFood.id
      );

      // replace the food item with the new food item
      foodItems[index] = editFood;

      // set the food items
      localStorage.setItem('foodItems', JSON.stringify(foodItems));

      // set the data
      setData(foodItems);
    }
  }, [editFood]);

  return (
    <div
      className="bg-[#fefefe] min-h-screen pb-2 flex flex-col items-center gap-6"
      style={{
        backgroundColor: '#ffffff',
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%235a380a' fill-opacity='0.025'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      }}
      key="admin"
    >
      {editFood.id !== '-1' && (
        <AdminEditCard food={editFood} setEditFood={setEditFood} />
      )}
      {'2323' === adp ? (
        <div className="flex flex-col gap-4">
          {/* search bar with filters */}
          <AdminSearchBar query={query} setQuery={setQuery} />

          {/* display data */}
          <div className="flex flex-row flex-wrap lg:gap-5 gap-1 px-6 items-center justify-center">
            {filteredData.length > 0
              ? filteredData.map((food: Food, index: number) => {
                  return (
                    <AdminItem
                      food={food}
                      key={index}
                      setEditFood={setEditFood}
                    />
                  );
                })
              : data.length > 0 &&
                data.map((food: Food, index: number) => {
                  return (
                    <AdminItem
                      food={food}
                      key={index}
                      setEditFood={setEditFood}
                    />
                  );
                })}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center w-screen h-screen gap-2">
          <label className="text-slate-700 text-xl font-bold">
            Admin Login:
          </label>
          <input
            type="text"
            placeholder="passcode"
            className="outline-none rounded-lg h-[5%] bg-gray-200 text-center text-slate-700 placeholder:text-slate-500 font-semibold"
            value={adp}
            onChange={(e) => setAdp(e.target.value)}
          />
        </div>
      )}
    </div>
  );
}
