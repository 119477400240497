import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../App';
import CartItem from '../components/CartItem';
import CommentBox from '../components/CommentBox';
import Loading from '../components/Loading';
import { Food } from '../interfaces/Food';

export default function CartPage() {
  const { cart, sumFood } = useContext(AppContext);

  const [subtotal, setSubtotal] = useState<number>(0);
  const [updateSum, setUpdateSum] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getSquareCart = (cart: Food[]) => {
    const data: SquareCheckout[] = [];

    if (cart) {
      for (let item of cart) {
        const newItem: SquareCheckout = {
          name: item.name || '',
          quantity: item.quantity?.toString() || '1',
          base_price_money: {
            amount:
              Number(item.price) +
              (item.customization
                ? item.customization!.reduce(
                    (total, item) => total + Number(item.price),
                    0
                  )
                : 0),
            currency: 'USD',
          },
        };

        data.push(newItem);
      }
    }

    return data;
  };

  useEffect(() => {
    setSubtotal(sumFood!());
    setUpdateSum(false);
  }, [updateSum, sumFood, cart, subtotal]);

  return (
    <div
      className="bg-transparent min-h-screen py-6 px-5 flex flex-col justify-start items-center space-y-6"
      style={{
        backgroundColor: '#ffffff',
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%235a380a' fill-opacity='0.025'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      }}
    >
      <div className="flex flex-col justify-center items-center md:max-w-5xl gap-2">
        {/* header */}
        <div>
          <h2 className="text-3xl font-semibold text-[#6c3b17]">Cart</h2>
        </div>

        <hr className="bg-slate-700 w-[80%]" />

        {cart?.length! > 0 ? (
          <div className="flex flex-col justify-center items-center gap-2">
            {/* loop cart */}
            <div className="flex flex-col gap-3">
              {cart &&
                cart.map((food: Food, index: number) => {
                  return (
                    <CartItem
                      food={food}
                      index={index}
                      setUpdateSum={setUpdateSum}
                      updateSum={updateSum}
                      key={index}
                    />
                  );
                })}
            </div>

            <hr className="bg-slate-700 w-[80%]" />

            <CommentBox />

            <hr className="bg-slate-700 w-[80%]" />

            {/* totals */}
            <div className="flex flex-col justify-start w-full">
              {/* totals listed */}
              <div className="flex flex-row justify-between w-full text-xl text-[#6c3b17] gap-4 px-5">
                <div className="space-y-1">
                  <div>Subtotal:</div>
                  <div>Fees&Taxes:</div>
                  <div className="font-medium">Total:</div>
                </div>

                <div className="space-y-1 text-xl">
                  <div>${subtotal}</div>
                  <div>
                    ${(subtotal * 0.06625 + subtotal * 0.01).toFixed(2)}
                  </div>
                  <div className="font-medium">
                    ${(subtotal * 0.06625 + subtotal).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>

            {/* pay */}
            <div className="flex flex-row pt-4 w-full justify-center">
              {cart?.length! > 0 && (
                <button
                  className={`btn hover:border-none hover:bg-white ${
                    loading ? `border-white` : `border-[#6c3b17]`
                  } border-[1px] bg-white text-[#6c3b17]`}
                  onClick={async () => {
                    setLoading(true);
                    try {
                      if (cart!.length! > 0) {
                        const rawResponse = await fetch(
                          'https://r1isfpy1tg.execute-api.us-east-1.amazonaws.com/prod/checkout/',
                          {
                            method: 'POST',
                            body: JSON.stringify({
                              line_items: getSquareCart(cart!),
                            }),
                          }
                        );

                        const content = await rawResponse.json();
                        // 1 second timer
                        setTimeout(() => {
                          setLoading(false);
                        }, 700);

                        // redirect to square checkout
                        // add a 1 second delay
                        setTimeout(() => {
                          window.location.href = content.body['url'];
                        }, 1000);

                        // save oderId to local storage
                        localStorage.setItem(
                          'orderId',
                          content.body['orderId']
                        );
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  {loading ? (
                    <div className="">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-white fill-[#DFC4BC]"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                  ) : (
                    'Checkout'
                  )}
                </button>
              )}
            </div>
          </div>
        ) : (
          <p className="text-primary">looking really empty...</p>
        )}
      </div>
    </div>
  );
}

interface SquareCheckout {
  name: string;
  quantity: string;
  base_price_money: {
    amount: number;
    currency: 'USD';
  };
}
