import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../App';

export default function CartTab() {
  const { cart, sumFood } = useContext(AppContext);
  return (
    <Link to="/cart">
      <div className="fixed bottom-0 -right-1 z-30 flex justify-end items-end pb-4">
        <div className="flex flex-row gap-2 bg-[#ffb09d] py-3 px-2 rounded-lg shadow active:scale-125 transition-all opacity-95">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="fill-current text-slate-700"
          >
            <path d="M11.476 8.853c-.469-.288-.616-.903-.328-1.372l3.004-5.004c.191-.312.517-.477.849-.477.776 0 1.259.855.851 1.519l-3.005 5.005c-.294.479-.91.611-1.371.329zm3.426.147l-.351.569c-.548.896-1.503 1.431-2.553 1.431-1.202 0-2.359-.72-2.812-2h-9.186v2h.643c.535 0 1.021.304 1.256.784l4.101 10.216h12l4.102-10.214c.234-.481.722-.786 1.256-.786h.642v-2h-9.098zm-5.902 9c0 .552-.447 1-1 1s-1-.448-1-1v-4c0-.552.447-1 1-1s1 .448 1 1v4zm4 0c0 .552-.447 1-1 1s-1-.448-1-1v-4c0-.552.447-1 1-1s1 .448 1 1v4zm4 0c0 .552-.447 1-1 1s-1-.448-1-1v-4c0-.552.447-1 1-1s1 .448 1 1v4z" />
          </svg>
          <p className="font-semibold text-slate-700 text-lg">
            {cart!.length === 0
              ? 'CART'
              : '(' +
                (cart && cart.length) +
                ') $' +
                (sumFood && sumFood(cart!))}
          </p>
        </div>
      </div>
    </Link>
  );
}
