import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../App';
import type { Food } from '../interfaces/Food';

interface Props {
  food: Food;
}

export default function Item(props: Props) {
  const { setTmpFood } = useContext(AppContext);

  // cents to dollar
  const convertCentsToDollar = (amount: string): number => {
    return parseInt(amount, 10) / 100;
  };

  return (
    <div>
      <div className="card card-compact rounded-lg w-full bg-white">
        <div className="card-body flex-row justify-center items-center">
          {/* left, image */}
          <div className="flex flex-col justify-center items-center w-[50%]">
            <img
              src={props.food.imgURL!}
              alt="food"
              className="rounded-lg aspect-auto"
            />
          </div>

          {/* middle name and price */}
          <div className="flex flex-col w-[80%]">
            {/* name */}
            <p
              className={
                'font-medium text-[#6c3b17] ' +
                (props.food.name!.length > 60 ? 'text-[0.3rem]' : 'text-lg')
              }
            >
              {props.food.name!.toUpperCase()}
            </p>

            {!props.food.description ? (
              <p className="font-medium text-xl text-[#6c3b17]">
                ${convertCentsToDollar(props.food.price!).toFixed(2)}
              </p>
            ) : (
              <p className="text-[#6c3b17] text-sm">
                {props.food.description!.toUpperCase()}
              </p>
            )}

            {/* description */}
          </div>

          {/* right button */}
          <div className="flex flex-col justify-center items-center gap-2">
            {props.food.description && props.food.description.length > 0 && (
              <>
                {/* price */}
                <p className="font-medium text-xl text-[#6c3b17]">
                  ${convertCentsToDollar(props.food.price!).toFixed(2)}
                </p>
              </>
            )}
            {/* button */}
            <Link
              to="/customize"
              onClick={() => {
                setTmpFood!(props.food);
              }}
            >
              <button className="btn hover:border-none hover:bg-white border-[#6c3b17] border-[1px] bg-white text-[#6c3b17] w-28 h-14">
                add to cart
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* <hr className="border-slate-400" /> */}
    </div>
  );
}
