import { Food, Menu } from '../interfaces/Food';

const Egg: Food = {
  price: '100',
  name: 'Egg',
  type: 'egg',
};

const EggWhites: Food = {
  price: '100',
  name: 'Egg Whites',
  type: 'egg',
};

// four types of cheeses
const American: Food = {
  price: '50',
  name: 'American',
  type: 'cheese',
};

const Swiss: Food = {
  price: '50',
  name: 'Swiss',
  type: 'cheese',
};

const Mozzarella: Food = {
  price: '50',
  name: 'Mozzarella',
  type: 'cheese',
};

const Provolone: Food = {
  price: '50',
  name: 'Provolone',
  type: 'cheese',
};

// 10 types of meat
const Ham: Food = {
  price: '250',
  name: 'Ham',
  type: 'meat',
};

const TaylorHam: Food = {
  price: '200',
  name: 'Taylor Ham',
  type: 'meat',
};

const Bacon: Food = {
  price: '100',
  name: 'Bacon',
  type: 'meat',
};

const TurkeyBacon: Food = {
  price: '100',
  name: 'Turkey Bacon',
  type: 'meat',
};

const TurkeySausage: Food = {
  price: '200',
  name: 'Turkey Sausage',
  type: 'meat',
};

const Chorizo: Food = {
  price: '200',
  name: 'Chorizo',
  type: 'meat',
};

const HotSausage: Food = {
  price: '200',
  name: 'Hot Sausage',
  type: 'meat',
};

const ItalianSausage: Food = {
  price: '200',
  name: 'Italian Sausage',
  type: 'meat',
};

const BeefSausage: Food = {
  price: '200',
  name: 'Beef Sausage',
  type: 'meat',
};

const Steak: Food = {
  price: '200',
  name: 'Steak',
  type: 'meat',
};

// basic foods
const Pepper: Food = {
  price: '0',
  name: 'Pepper',
  type: 'basic',
};

const Ketchup: Food = {
  price: '0',
  name: 'Ketchup',
  type: 'basic',
};

const Mayo: Food = {
  price: '0',
  name: 'Mayo',
  type: 'basic',
};

const ChipotleSauce: Food = {
  price: '0',
  name: 'Chipotle Sauce',
  type: 'basic',
};

const Salt: Food = {
  price: '0',
  name: 'Salt',
  type: 'basic',
};

const RanchDressing: Food = {
  price: '0',
  name: 'Ranch Dressing',
  type: 'basic',
};

const ItalianDressing: Food = {
  price: '0',
  name: 'Italian Dressing',
  type: 'basic',
};

const Balsamic: Food = {
  price: '0',
  name: 'Balsamic',
  type: 'basic',
};

const CeasarDressing: Food = {
  price: '0',
  name: 'Caesar  Dressing',
  type: 'basic',
};

const OilVinegar: Food = {
  price: '0',
  name: 'Oil & Vinegar',
  type: 'basic',
};

const SweetChili: Food = {
  price: '0',
  name: 'Sweet Chili',
  type: 'basic',
};

const BBQSauce: Food = {
  price: '0',
  name: 'BBQ Sauce',
  type: 'basic',
};

const SalsaSauce: Food = {
  price: '0',
  name: 'Salsa Sauce',
  type: 'basic',
};

const PinkSauce: Food = {
  price: '0',
  name: 'Pink Sauce',
  type: 'basic',
};

const HotSauce: Food = {
  price: '0',
  name: 'Hot Sauce',
  type: 'basic',
};

const Teriyaki: Food = {
  price: '0',
  name: 'Teriyaki',
  type: 'basic',
};

const PeppercornRanch: Food = {
  price: '0',
  name: 'Peppercorn Ranch',
  type: 'basic',
};

const Spinach: Food = {
  price: '0',
  name: 'Spinach',
  type: 'basic',
};

const Onions: Food = {
  price: '0',
  name: 'Onions',
  type: 'basic',
};

const Peppers: Food = {
  price: '0',
  name: 'Peppers',
  type: 'basic',
};

const Mushroom: Food = {
  price: '0',
  name: 'Mushroom',
  type: 'basic',
};

const Tomato: Food = {
  price: '0',
  name: 'Tomato',
  type: 'basic',
};

const BlackOlives: Food = {
  price: '0',
  name: 'BlackOlives',
  type: 'basic',
};

const Splenda: Food = {
  price: '0',
  name: 'Splenda',
  type: 'sugar',
};

const SweetAndLow: Food = {
  price: '0',
  name: 'Sweet And Low',
  type: 'sugar',
};

const Equal: Food = {
  price: '0',
  name: 'Equal',
  type: 'sugar',
};

const Sugar: Food = {
  price: '0',
  name: 'Sugar',
  type: 'sugar',
};

const Pancake: Food = {
  price: '100',
  name: 'Pancake',
  type: 'bread',
};

const Lipton: Food = {
  price: '0',
  name: 'Lipton',
  type: 'tea',
};

const LemonGinger: Food = {
  price: '0',
  name: 'Lemon Ginger',
  type: 'tea',
};

const GreenTea: Food = {
  price: '0',
  name: 'Green Tea',
  type: 'tea',
};

const Camomile: Food = {
  price: '0',
  name: 'Camomile',
  type: 'tea',
};

const LemonZinger: Food = {
  price: '0',
  name: 'Lemon Zinger',
  type: 'tea',
};

const Whole: Food = {
  price: '0',
  name: 'Whole',
  type: 'milk',
};

const Skim: Food = {
  price: '0',
  name: 'Skim',
  type: 'milk',
};

const Almond: Food = {
  price: '0',
  name: 'Almond',
  type: 'milk',
};

const percent_2: Food = {
  price: '0',
  name: '2%',
  type: 'milk',
};

const BlackCoffee: Food = {
  price: '0',
  name: 'Black Coffee',
  type: 'coffee',
};

const DarkCoffee: Food = {
  price: '0',
  name: 'Dark Coffee',
  type: 'coffee',
};

const MediumCoffee: Food = {
  price: '0',
  name: 'Medium Coffee',
  type: 'coffee',
};

const LightCoffee: Food = {
  price: '0',
  name: 'Light Coffee',
  type: 'coffee',
};

export const menuData: Menu[] = [
  {
    category: 'snacks',
    menu: [
      {
        price: '50',
        quantity: 1,
        name: 'Lays Potatoes Chips',
        type: 'snacks',
        imgURL:
          'https://www.staples-3p.com/s7/is/image/Staples/sp44851617_sc7?wid=700&hei=700',
      },
      {
        price: '125',
        quantity: 1,
        name: 'BBQ Chips',
        type: 'snacks',
        imgURL:
          'https://target.scene7.com/is/image/Target/GUEST_66e87742-9805-4001-83b3-ebd86c100f1d?wid=626&hei=626&qlt=80&fmt=pjpeg',
      },
      {
        price: '125',
        quantity: 1,
        name: 'Jalapeño Chips',
        type: 'snacks',
        imgURL:
          'https://i5.walmartimages.com/asr/e142a91e-e5b5-4621-8f48-af446ce81519_1.243d16d0bdca2adc2706918e08c9f805.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF',
      },
      {
        price: '125',
        quantity: 1,
        name: 'Doritos Cool Ranch Chips',
        type: 'snacks',
        imgURL: 'https://m.media-amazon.com/images/I/81-DyMXy6tL._SL1500_.jpg',
      },
      {
        price: '125',
        quantity: 1,
        name: 'Cheetos Chips',
        type: 'snacks',
        imgURL:
          'https://images.heb.com/is/image/HEBGrocery/001962881?fit=constrain,1&wid=800&hei=800&fmt=jpg&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0',
      },
      {
        price: '125',
        quantity: 1,
        name: 'Fritos Chips',
        type: 'snacks',
        imgURL:
          'https://target.scene7.com/is/image/Target/GUEST_5cfe1a12-f114-4217-8596-9aed252a3110?wid=725&hei=725&qlt=80&fmt=webp',
      },
      {
        price: '150',
        quantity: 1,
        name: 'Sweet Salty Nuts',
        type: 'snacks',
        imgURL:
          'https://m.media-amazon.com/images/I/51j+fvunaDL._SY300_SX300_.jpg',
      },
      {
        price: '150',
        quantity: 1,
        name: 'Crunchy Oats Honey',
        type: 'snacks',
        imgURL:
          'https://www.staples-3p.com/s7/is/image/Staples/sp130645986_sc7?wid=700&hei=700',
      },
      {
        price: '150',
        quantity: 1,
        name: 'Trail Mix',
        type: 'snacks',
        imgURL:
          'https://static.grainger.com/rp/s/is/image/Grainger/38EC30_AS01?hei=536&wid=536&$adapimg$=',
      },
      {
        price: '350',
        quantity: 1,
        name: '5 Hour Energy',
        type: 'snacks',
        imgURL:
          'https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=2688,height=1512,format=auto/https://doordash-static.s3.amazonaws.com/media/photosV2/ddc82d08-8c59-4b71-8c94-6833cd08a785-retina-large.jpg',
      },
      {
        price: '125',
        quantity: 1,
        name: "Mrs Vicky's Chips Sea Salt & Vinegar",
        type: 'snacks',
        imgURL:
          'https://i5.walmartimages.com/asr/c2171816-1f67-499d-abaa-db8b5165d74e_1.232798ca3778bf85ea740ab31d3c815c.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF',
      },
      {
        price: '125',
        quantity: 1,
        name: "Mrs Vicky's Smokehouse BBQ Chips",
        type: 'snacks',
        imgURL:
          'https://i5.walmartimages.com/asr/22d27011-1f90-420f-973d-4e620f20ca68.b4540ee2eab5ef1e48a8097db656adc5.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF',
      },
      {
        price: '150',
        quantity: 1,
        name: 'Peanuts Cashews',
        type: 'snacks',
        imgURL:
          'https://www.instacart.com/image-server/466x466/filters:fill(FFF,true):format(webp)/www.instacart.com/assets/domains/product-image/file/large_225d5fc3-0ce2-4636-a00f-fcc8104042a2.jpg',
      },
      {
        price: '150',
        quantity: 1,
        name: 'Trident Gum',
        type: 'snacks',
        imgURL:
          'https://images.heb.com/is/image/HEBGrocery/000027388?fit=constrain,1&wid=800&hei=800&fmt=jpg&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0',
      },
      {
        price: '350',
        quantity: 1,
        name: 'Grits',
        type: 'snacks',
        imgURL: 'https://m.media-amazon.com/images/I/81C8NdA59bL._SL1500_.jpg',
        extra: [
          {
            price: '50',
            quantity: 1,
            name: 'cheese',
            type: 'cheese',
          },
        ],
      },
      {
        price: '175',
        quantity: 1,
        name: 'Halls',
        type: 'snacks',
        imgURL:
          'https://i5.walmartimages.com/asr/11c1ae2d-1d1c-4c55-b8c7-f4d93c88ad37_1.c90f9bcc5a58172b24e536a610bfdce4.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Oatmeal Cookie',
        type: 'snacks',
        imgURL:
          'https://cdn.shopify.com/s/files/1/1540/1281/products/OatmealRaisincookie_BangCookies_576x.png?v=1668010485',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Oreo Cookies',
        type: 'snacks',
        imgURL:
          'https://de2wfhoo6xqi5.cloudfront.net/size/600/a6b/bd9/a0615fbf5c4da5cf774bd0ae8d8356ca1e.jpg',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Chocolate Chip Cookie',
        type: 'snacks',
        imgURL:
          'https://www.foodservicedirect.com/media/catalog/product/1/4/149136.jpg?width=1200&height=1200&quality=85&fit=bounds',
      },
      {
        price: '300',
        quantity: 1,
        name: 'Honey Nut Cheerios Cereal',
        type: 'snacks',
        imgURL: 'https://pics.drugstore.com/prodimg/440228/450.jpg',
      },
      {
        price: '300',
        quantity: 1,
        name: 'Frosted Flakes Cereal',
        type: 'snacks',
        imgURL: 'https://m.media-amazon.com/images/I/81mpZN3EHiL._SL1500_.jpg',
      },
      {
        price: '300',
        quantity: 1,
        name: 'Cinnamon brown sugar oatmeal',
        type: 'snacks',
        imgURL:
          'https://www.quakeroats.com/images/default-source/products/io_maplebrownsugar_640x800',
      },
      {
        price: '150',
        quantity: 1,
        name: 'Peanuts Salted',
        type: 'snacks',
        imgURL:
          'https://www.webstaurantstore.com/images/products/extra_large/525655/2309209.jpg',
      },
      {
        price: '150',
        quantity: 1,
        name: 'Pistachios',
        type: 'snacks',
        imgURL:
          'https://www.riteaid.com/shop/media/catalog/product/y/m/ymk9cbfetg514nopmkb4.jpg?quality=80&bg-color=255,255,255&fit=bounds&height=406&width=406&canvas=406:406',
      },
      {
        price: '150',
        quantity: 1,
        name: 'Oreos',
        type: 'snacks',
        imgURL:
          'https://i5.walmartimages.com/asr/32a75ea4-eee9-4bd6-8e31-bb9e5b46d187.dd131be7370b41bddfd7750fcbc8b20c.jpeg',
      },
      {
        price: '150',
        quantity: 1,
        name: 'Sunflower Seeds',
        type: 'snacks',
        imgURL:
          'https://i5.walmartimages.com/asr/399b4bc8-1e42-4239-b12d-7c74115d2d9a_1.acfded5f4c8660bf6f432991ff19f7a3.jpeg?odnHeight=2000&odnWidth=2000&odnBg=FFFFFF',
      },
    ],
  },
  {
    category: 'drinks',
    menu: [
      {
        price: '250',
        quantity: 1,
        name: 'Orange Juice',
        type: 'drinks',
        imgURL:
          'https://cdn.shopify.com/s/files/1/0575/2530/5518/products/QKR55154.jpg?v=1654874751',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Chocolate Milk',
        type: 'drinks',
        imgURL:
          'https://www.goodnes.com/sites/g/files/jgfbjl321/files/styles/gdn_hero_pdp_product_image/public/gdn_product/field_product_images/nesquik-hohbqjdajf8qiyziuil2.png.webp?itok=80AwzfaY',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Apple Juice',
        type: 'drinks',
        imgURL:
          'https://beverageuniverse.com/media/catalog/product/cache/3c678337c584a90d1f06396ba3f03f8e/t/r/tropicana-ztaj-main.jpg',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Cranberry Juice',
        type: 'drinks',
        imgURL: 'https://www.quill.com/is/image/Quill/sp24455817_s7?$img400$',
      },
      {
        price: '350',
        quantity: 1,
        name: 'Red Bull',
        type: 'drinks',
        imgURL:
          'https://images.heb.com/is/image/HEBGrocery/001476509?fit=constrain,1&wid=800&hei=800&fmt=jpg&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0',
      },
      {
        price: '350',
        quantity: 1,
        name: 'Muscle Milk',
        type: 'drinks',
        imgURL:
          'https://i5.walmartimages.com/asr/639c16bc-6e08-4672-9e6c-1cc9e52a0251.09d34d4f9cb0255541cb10f740bb9519.jpeg?odnHeight=2000&odnWidth=2000&odnBg=FFFFFF',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Coca Cola',
        type: 'drinks',
        imgURL:
          'https://target.scene7.com/is/image/Target/GUEST_7819ee30-1f78-46ee-a21c-d2096f99ba42?wid=665&hei=665&qlt=80&fmt=webp',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Diet Coca Cola',
        type: 'drinks',
        imgURL:
          'https://target.scene7.com/is/image/Target/GUEST_df0ed645-55ba-440c-a765-e702bc9ebb01?wid=665&hei=665&qlt=80&fmt=webp',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Pepsi',
        type: 'drinks',
        imgURL:
          'https://target.scene7.com/is/image/Target/GUEST_1361a461-e2b2-4de2-98aa-4007e6d8b5c2?wid=665&hei=665&qlt=80&fmt=webp',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Diet Pepsi',
        type: 'drinks',
        imgURL:
          'https://target.scene7.com/is/image/Target/GUEST_85b28297-9ac0-45f0-9d57-adab6d1e922a?wid=665&hei=665&qlt=80&fmt=webp',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Gingerale',
        type: 'drinks',
        imgURL:
          'https://i5.walmartimages.com/asr/692b92fa-95ce-4159-a141-e50a62058319.c14e1ef509e2e27f39a0757c1ae5a5c0.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Sunkis Orange',
        type: 'drinks',
        imgURL:
          'https://i5.walmartimages.com/asr/e989ec0b-2ce7-479c-80f6-c4834b44bc1b.a05e3af8391274abc92f1e32460b972d.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Seltzer Water',
        type: 'drinks',
        imgURL:
          'https://beverageuniverse.com/media/catalog/product/cache/3c678337c584a90d1f06396ba3f03f8e/c/a/canada-dry-soda-xysz-main.jpg',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Canada Dry',
        type: 'drinks',
        imgURL:
          'https://beverageuniverse.com/media/catalog/product/cache/3c678337c584a90d1f06396ba3f03f8e/c/a/canada-dry-soda-xxxr-xysz20-main.jpg',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Chocolate Milk',
        type: 'drinks',
        imgURL:
          'https://i5.walmartimages.com/asr/a9aebe43-d311-4bcc-8f47-3516c6ba115b_1.58e483fb0042f1a1c59b846ce6fe9857.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF',
      },
      {
        price: '300',
        quantity: 1,
        name: 'Starbucks Coffee',
        type: 'drinks',
        imgURL:
          'https://i5.walmartimages.com/asr/8ff34e3e-44fa-4825-bbae-93f424043a8c.fac8fa2052d7c06f2fd1f0de843fdc69.jpeg?odnHeight=2000&odnWidth=2000&odnBg=FFFFFF',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Gatorade',
        type: 'drinks',
        imgURL: 'https://m.media-amazon.com/images/I/71yCthJHMUL._SL1500_.jpg',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Arizona Green Tea',
        type: 'drinks',
        imgURL:
          'https://www.dollartree.com/ccstore/v1/images/?source=/file/v4173213138814229115/products/305933.jpg',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Coconut Water',
        type: 'drinks',
        imgURL:
          'https://www.mercadomagico.com/images/thumbnails/465/465/detailed/4942/KHRM02202915-0.jpg',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Vtamin Water',
        type: 'drinks',
        imgURL:
          'https://target.scene7.com/is/image/Target/GUEST_ab04d8f2-6e18-4f1e-bd90-b74f6bfc81d8?wid=665&hei=665&qlt=80&fmt=webp',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Snapple Peach',
        type: 'drinks',
        extra: [
          {
            price: '0',
            quantity: 1,
            name: 'Peach',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Peach ZERO',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Orange',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Ice Tea',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Ice Tea Diet',
            type: 'type',
          },
        ],
        imgURL:
          'https://beverageuniverse.com/media/catalog/product/cache/3c678337c584a90d1f06396ba3f03f8e/s/n/snapple_peach_tea_1.jpg',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Snapple Peach ZERO',
        type: 'drinks',
        extra: [
          {
            price: '0',
            quantity: 1,
            name: 'Peach',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Peach ZERO',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Orange',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Ice Tea',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Ice Tea Diet',
            type: 'type',
          },
        ],
        imgURL:
          'https://target.scene7.com/is/image/Target/GUEST_1a924440-01a4-403d-ba4e-f6e1aa33098a?wid=665&hei=665&qlt=80&fmt=webp',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Snapple Orange',
        type: 'drinks',
        extra: [
          {
            price: '0',
            quantity: 1,
            name: 'Peach',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Peach ZERO',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Orange',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Ice Tea',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Ice Tea Diet',
            type: 'type',
          },
        ],
        imgURL: 'https://m.media-amazon.com/images/I/41bFJF-XsfL.jpg',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Snapple Ice Tea',
        type: 'drinks',
        extra: [
          {
            price: '0',
            quantity: 1,
            name: 'Peach',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Peach ZERO',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Orange',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Ice Tea',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Ice Tea Diet',
            type: 'type',
          },
        ],
        imgURL:
          'https://beverageuniverse.com/media/catalog/product/cache/3c678337c584a90d1f06396ba3f03f8e/s/n/snapple_lemon_tea_3.jpg',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Snapple Ice Tea Diet',
        type: 'drinks',
        extra: [
          {
            price: '0',
            quantity: 1,
            name: 'Peach',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Peach ZERO',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Orange',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Ice Tea',
            type: 'type',
          },
          {
            price: '0',
            quantity: 1,
            name: 'Ice Tea Diet',
            type: 'type',
          },
        ],
        imgURL:
          'https://beverageuniverse.com/media/catalog/product/cache/3c678337c584a90d1f06396ba3f03f8e/s/n/snapple_diet_lemon_tea_2.jpg',
      },
      {
        price: '100',
        quantity: 1,
        name: 'Water',
        type: 'drinks',
        imgURL:
          'https://beverageuniverse.com/media/catalog/product/cache/3c678337c584a90d1f06396ba3f03f8e/p/o/poland_spring-fcps-main.jpg',
      },
      {
        price: '250',
        quantity: 1,
        name: 'Arizona Honey Ginger',
        type: 'drinks',
        imgURL:
          'https://images.heb.com/is/image/HEBGrocery/001071026?fit=constrain,1&wid=800&hei=800&fmt=jpg&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0',
      },
      {
        price: '350',
        quantity: 1,
        name: 'Monster ZERO',
        type: 'drinks',
        imgURL:
          'https://images.heb.com/is/image/HEBGrocery/001621707?fit=constrain,1&wid=800&hei=800&fmt=jpg&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0',
      },
      {
        price: '350',
        quantity: 1,
        name: 'Coconut Water',
        type: 'drinks',
        imgURL:
          'https://www.webstaurantstore.com/images/products/extra_large/611284/2187196.jpg',
      },
    ],
  },
  {
    category: 'pastries',
    menu: [
      {
        price: '200',
        quantity: 1,
        name: 'Muffins',
        type: 'pastries',
        imgURL:
          'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F19%2F2011%2F04%2F08%2Fchocolate-chip-muffins-ck-2000.jpg&q=60',
      },
      {
        price: '200',
        quantity: 1,
        name: 'Coffee Cake',
        type: 'pastries',
        imgURL:
          'https://cookiesandcups.com/wp-content/uploads/2019/09/coffeecake-5.jpg',
      },
      {
        price: '200',
        quantity: 1,
        name: 'Banana Cake',
        type: 'pastries',
        imgURL:
          'https://sallysbakingaddiction.com/wp-content/uploads/2016/08/the-best-banana-cake-3.jpg',
      },
      {
        price: '200',
        quantity: 1,
        name: 'Danish',
        type: 'pastries',
        imgURL:
          'https://insanelygoodrecipes.com/wp-content/uploads/2021/02/Homemade-Danish-Pastry-with-Coffee-683x1024.webp',
      },
      {
        price: '200',
        quantity: 1,
        name: 'Croissant',
        type: 'pastries',
        imgURL:
          'https://www.theflavorbender.com/wp-content/uploads/2020/05/French-Croissants-2522-699x1047.jpg',
      },
      {
        price: '175',
        quantity: 1,
        name: 'Butter Roll',
        type: 'pastries',
        imgURL:
          'https://www.sidechef.com/recipe/45bc2078-eb7b-4cd4-9af6-5e173a320145.jpg',
      },
    ],
  },
  {
    category: 'hot drinks',
    menu: [
      {
        price: '175',
        quantity: 1,
        name: 'Tea',
        type: 'hot drinks',
        sizes: [
          {
            price: '50',
            quantity: 1,
            name: 'small',
            type: 'size',
          },
          // {
          //   price: '75',
          //   quantity: 1,
          //   name: 'medium',
          //   type: 'size',
          // },
          {
            price: '100',
            quantity: 1,
            name: 'large',
            type: 'size',
          },
        ],
        milk: [Whole, Skim, percent_2, Almond],
        sugars: [Splenda, SweetAndLow, Equal, Sugar],
        tea: [Lipton, LemonGinger, Camomile, LemonZinger, GreenTea],
        customization: [],
        imgURL:
          'https://img.livestrong.com/630x/cme-data/getty%2F7290e8c633fb4daabe4807dfd4d83ab0.jpg?type=webp',
      },
      {
        price: '175',
        quantity: 1,
        name: 'Coffee',
        type: 'hot drinks',
        sizes: [
          {
            price: '50',
            quantity: 1,
            name: 'small',
            type: 'size',
          },
          // {
          //   price: '75',
          //   quantity: 1,
          //   name: 'medium',
          //   type: 'size',
          // },
          {
            price: '100',
            quantity: 1,
            name: 'large',
            type: 'size',
          },
        ],
        coffeeStyle: [BlackCoffee, DarkCoffee, MediumCoffee, LightCoffee],
        milk: [Whole, Skim, percent_2, Almond],
        sugars: [Splenda, SweetAndLow, Equal, Sugar],
        customization: [],
        imgURL:
          'https://www.foodrepublic.com/wp-content/uploads/2016/11/7417277946_9241b1443a_b-700x506.jpg',
      },
      {
        price: '175',
        quantity: 1,
        name: 'Hot Chocolate',
        type: 'hot drinks',
        sizes: [
          {
            price: '50',
            quantity: 1,
            name: 'small',
            type: 'size',
          },
          // {
          //   price: '75',
          //   quantity: 1,
          //   name: 'medium',
          //   type: 'size',
          // },
          {
            price: '100',
            quantity: 1,
            name: 'large',
            type: 'size',
          },
        ],
        sugars: [Splenda, SweetAndLow, Equal, Sugar],
        customization: [],
        imgURL:
          'https://www.willcookforsmiles.com/wp-content/uploads/2019/11/Hot-Chocolate-5-1.jpg',
      },
    ],
  },
  {
    category: 'breakfast',
    menu: [
      {
        price: '600',
        quantity: 1,
        name: 'Original Classic',
        description: 'Egg, Cheese, with your choice Meat',
        ingredients: [Egg, Mozzarella],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        baseOption: [
          'Platter',
          'Rye',
          'Wrap',
          'Roll',
          'Whole Wheat',
          'White Bread',
          'Croissant',
        ],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [Egg],
        customization: [],
        type: 'breakfast',
        imgURL:
          'https://vintagerecipeproject.com/wp-content/uploads/2017/12/breakfast-recipes-735x490.jpg',
      },
      {
        price: '800',
        quantity: 1,
        name: 'Breakfast Burrito',
        description:
          'Chorizo, Rice & Beans with scrambled eggs, melted American Cheese and chipotle sauce on a Whole Wheat Wrap.',
        baseOption: [
          'Platter',
          'Rye',
          'Wrap',
          'Roll',
          'Whole Wheat',
          'White Bread',
          'Croissant',
        ],
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [Egg],
        customization: [],
        type: 'breakfast',
        imgURL:
          'https://i0.wp.com/www.onceuponachef.com/images/2018/03/Breakfast-Burritos.jpg?resize=760%2C569&ssl=1',
      },
      {
        price: '700',
        quantity: 1,
        name: 'BLT',
        description: 'Lettuce, Tomato and you choice Meat',
        meatOption: [Bacon, TurkeyBacon],
        baseOption: [
          'Platter',
          'Rye',
          'Wrap',
          'Roll',
          'Whole Wheat',
          'White Bread',
          'Croissant',
        ],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        customization: [],
        type: 'breakfast',
        imgURL:
          'https://therecipecritic.com/wp-content/uploads/2022/05/blt-1-750x1000.jpg',
      },
      {
        price: '400',
        quantity: 1,
        name: 'Egg & Cheese',
        description: 'egg and cheese on toasted bread',
        baseOption: [
          'Platter',
          'Rye',
          'Wrap',
          'Roll',
          'Whole Wheat',
          'White Bread',
          'Croissant',
        ],
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [Egg],
        customization: [],
        type: 'breakfast',
        imgURL:
          'https://assets.bonappetit.com/photos/5a7cbe4a668fb87d44d15e0d/1:1/w_1920,c_limit/breakfast-grilled-cheese-with-soft-scrambled-eggs.jpg',
      },
      {
        price: '800',
        quantity: 1,
        name: 'Tukey Bacon Omlet Wrap',
        description:
          'Turkey Bacon, Omlet with Onions Peppers and spinach, melted mozzarella cheese',
        baseOption: [
          'Platter',
          'Rye',
          'Wrap',
          'Roll',
          'Whole Wheat',
          'White Bread',
          'Croissant',
        ],
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        veggieOptions: [
          Spinach,
          Onions,
          Peppers,
          Mushroom,
          Tomato,
          BlackOlives,
        ],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [Egg],
        customization: [],
        type: 'breakfast',
        imgURL:
          'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F44%2F2019%2F01%2F02%2F6218817.jpg',
      },
      {
        price: '900',
        quantity: 1,
        name: 'Protein Pancakes Platter',
        description: 'Egg whites, Turkey Bacon & Pancakes',
        ingredients: [
          { name: 'Syrup on top', price: '0' },
          { name: 'Syrup on side', price: '0' },
          { name: 'No Syrup', price: '0' },
        ],
        extra: [EggWhites],
        customization: [],
        type: 'breakfast',
        imgURL:
          'https://fedandfit.com/wp-content/uploads/2021/10/211018_Protein-Pancakes-1.jpg',
      },
      {
        price: '700',
        quantity: 1,
        name: 'Banana Protein Pancakes',
        description:
          'Fresh Bananas with Banana Pancakes served with butter and Syrup',
        ingredients: [
          { name: 'Syrup on top', price: '0' },
          { name: 'Syrup on side', price: '0' },
          { name: 'No Syrup', price: '0' },
        ],
        extra: [Pancake],
        customization: [],
        type: 'breakfast',
        imgURL:
          'https://healthyfitnessmeals.com/wp-content/uploads/2018/06/banana-protein-pancakes-3.jpg',
      },
      {
        price: '900',
        quantity: 1,
        name: 'Farm House',
        description:
          'Taylor Ham, Bacon, Egg, cream cheese, American Cheese on a toasted Croissant',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        veggieOptions: [
          Spinach,
          Onions,
          Peppers,
          Mushroom,
          Tomato,
          BlackOlives,
        ],
        extra: [Egg],
        customization: [],
        type: 'breakfast',
        imgURL:
          'https://neighborfoodblog.com/wp-content/uploads/2020/12/croissant-breakfast-sandwiches-2.jpg',
      },
      {
        price: '500',
        quantity: 1,
        name: 'Tica Rice Bowl',
        description:
          'Rice Bowl, chorizo, scramble eggs, fried peppers and onions',
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [Egg],
        customization: [],
        type: 'breakfast',
        imgURL:
          'https://farm6.staticflickr.com/5811/21433242912_4a9e4ba6ac_z.jpg',
      },
      {
        price: '400',
        quantity: 1,
        name: 'Grilled Cheese',
        description:
          'Toasted Grilled cheese, with your choice of bread and cheese',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        baseOption: [
          'Platter',
          'Rye',
          'Wrap',
          'Roll',
          'Whole Wheat',
          'White Bread',
          'Croissant',
        ],
        customization: [],
        type: 'breakfast',
        imgURL:
          'https://therecipecritic.com/wp-content/uploads/2022/08/grilledcheese-1-750x1000.jpg',
      },
    ],
  },
  {
    category: 'lunch',
    menu: [
      {
        price: '900',
        quantity: 1,
        name: "Martha's Chicken Panini",
        description:
          'Grilled Chicken, Roasted Peppers, Melted Mozzarella Cheese & Balsamic Dressing, Long Roll',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://www.thespruceeats.com/thmb/GmUm42NxAmmC-JbXRWEafZeVpAI=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/grilled-chicken-panini-sandwich-recipe-2097378-hero-01-09e7d3d7e8604a8a90217e6f9e356648.jpg',
      },
      {
        price: '900',
        quantity: 1,
        name: "Santi's Chicken Wrap",
        description:
          'Grilled Chicken Sauteed Peppers, Onions Crispy Bacon, Lettuce, Melted American Cheese & Sweet Chilli Sauce',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://leaf.nutrisystem.com/wp-content/uploads/2019/09/chicken-ranch-wrap.jpg',
      },
      {
        price: '900',
        quantity: 1,
        name: 'Ranchero Chicken Wrap',
        description:
          'Grilled Chicken, Lettuce, Tomato, Swiss Cheese & Ranch Dressing',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://i0.wp.com/easywraprecipes.com/wp-content/uploads/2019/12/chipotle-chicken-bacon-ranch-wrap-3.jpg?w=800&ssl=1',
      },
      {
        price: '900',
        quantity: 1,
        name: 'Portabella Cheese Steak',
        description:
          'Steak, Sauteed Peppers & Onions, Mushroom, Melted Provolone Cheese & Light Mayo',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://thenutfreevegan.net/wp-content/uploads/2020/12/portobello-cheesesteak-reshoot-4-WEB-scaled.jpg',
      },
      {
        price: '900',
        quantity: 1,
        name: 'Cheesesteak Sandwich',
        description:
          'Steak, Sauteed Peppers & Onions, Melted American Cheese & Chipotle Sauce',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://www.fromvalerieskitchen.com/wordpress/wp-content/uploads/2020/01/Philly-Cheesesteak-Recipe-18.jpg',
      },
      {
        price: '900',
        quantity: 1,
        name: 'Steak Ranchero',
        description:
          'Steak, Bacon, Sauteed Onions & Peppers,Jalapenos, Mozzarella Cheese &  Ranch Dressing',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://media-cdn.tripadvisor.com/media/photo-s/1c/d1/60/3d/all-american-chicken.jpg',
      },
      {
        price: '700',
        quantity: 1,
        name: 'Hawaiian Burger',
        description:
          'Burger, Bacon,Melted Mozzarella Cheese, Lettuce, Tomato, Onions, Pinapple',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://lifeloveandgoodfood.com/wp-content/uploads/2022/06/Hawaiian-Burger-10-1200x1800-1.jpg',
      },
      {
        price: '700',
        quantity: 1,
        name: `Sarai's Burger`,
        description:
          'Burger, Lettuce, Tomato, Mushrooms,Jalapenos, Provolone Cheese, Chipotle Sauce',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://www.willcookforsmiles.com/wp-content/uploads/2016/05/Spicy-Chipotle-Chicken-Burger-1.jpg',
      },
      {
        price: '700',
        quantity: 1,
        name: 'Quesadilla Burger',
        description:
          'Burger, Melted American Cheese,Lettuce, Sauteed Tomatos and Onions, Sour Cream',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://www.willcookforsmiles.com/wp-content/uploads/2015/05/Quesadilla-Burger-3-from-willcookforsmiles.com_.jpg',
      },
      {
        price: '900',
        quantity: 1,
        name: 'Chorizo Fiesta',
        description:
          'Chorizo, Sautee peppers and Onions, Provolone Cheese and Chipotle Sauce',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://www.keepingitsimpleblog.com/wp-content/uploads/2021/09/chorizo-frito-top-view-768x1024.jpg',
      },
      {
        price: '900',
        quantity: 1,
        name: 'Beef Sausage Wrap',
        description:
          'Beef Sausage, Sauteed Peppers and Onions & Chipotle Sauce',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://www.garnishwithlemon.com/wp-content/uploads/2017/06/Grilled-Italian-Sausage-Wraps-2017-1-of-2-980x1280.jpg',
      },
      {
        price: '900',
        quantity: 1,
        name: 'Mexican Hot Sausage',
        description:
          "Jalapeno's and Provolone Cheese, Sauteed Onions & Peppers, Hot Sauce",
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://spicysouthernkitchen.com/wp-content/uploads/sausage-and-peppers-19.jpg',
      },
      {
        price: '900',
        quantity: 1,
        name: 'Italian Sausage Sandwich',
        description:
          'Italian Sausage, Sauteed Peppers & Onions, Mustard on a Long Roll',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/HEYLGYCEOQI6XLBKHLAPFOGO5M.jpg&w=960',
      },
      {
        price: '900',
        quantity: 1,
        name: 'Kielbasa Salad Platter',
        description:
          'Lettuce, Tomato, Raw Onions and Peppers and Olives, Italian Dressing',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://www.the-girl-who-ate-everything.com/wp-content/uploads/2019/10/antipasto-salad-001-660x879.jpg',
      },
      {
        price: '900',
        quantity: 1,
        name: 'Ceasar Chicken Salad Platter',
        description:
          'Grilled Chicken, Swiss Cheese, Lettuce, Black Olives, Raw Onions & Peppers, Cucumbers, Mushroom & Ceasar Dressing',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://reciperunner.com/wp-content/uploads/2017/07/Grilled-Chicken-Caesar-Salad-Photograph.jpg',
      },
      {
        price: '700',
        quantity: 1,
        name: 'Tuna Salad Platter',
        description: 'Lettuce Tomato & Onions',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://umamigirl.com/wp-content/uploads/2020/03/Best-Tuna-Salad-Recipe-Umami-Girl-780.jpg',
      },
      {
        price: '700',
        quantity: 1,
        name: 'Chicken Salad Platter',
        description: 'Lettuce Tomato & Onions',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        meatOption: [
          Bacon,
          TurkeyBacon,
          TaylorHam,
          TurkeySausage,
          Chorizo,
          HotSausage,
          ItalianSausage,
          BeefSausage,
          Steak,
        ],
        customization: [],
        type: 'lunch',
        imgURL:
          'https://img.freepik.com/premium-photo/salad-chicken-breast-vegetable-tomato-onion-green-mix-leaves-lettuce-fresh-meal-food-snack_88242-19595.jpg?w=826',
      },
    ],
  },
  {
    category: 'special',
    menu: [
      {
        price: '1000',
        quantity: 1,
        name: 'Rice & Beans',
        description: 'rice, beans, and steak',
        cheeseOption: [American, Mozzarella, Swiss, Provolone],
        sauce: [Salt, Pepper, Ketchup, Mayo, ChipotleSauce],
        extra: [],
        customization: [],
        type: 'special',
        imgURL:
          'https://food.fnr.sndimg.com/content/dam/images/food/fullset/2007/12/7/1/IE0213_Red_Beans_and_Rice.jpg.rend.hgtvcom.686.515.suffix/1552488799980.jpeg',
      },
    ],
  },
];
