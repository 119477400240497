import React from 'react';
import { Food } from '../interfaces/Food';

interface Props {
  food: Food;
  setEditFood: Function;
}

export default function AdminItem(props: Props) {
  return (
    <div
      className="flex flex-col gap-2 bg-gray-200 p-4 rounded-lg"
      onClick={() => props.setEditFood(props.food)}
    >
      <label className="text-slate-700">{props.food.name}</label>
    </div>
  );
}
