import { useEffect, useState } from 'react';

export const useScrollLocation = () => {
  const threshold = 100;
  const [scrollDir, setScrollDir] = useState<boolean>(false);

  useEffect(() => {
    const updateScrollDirection = () => {
      const currentScrollYPosition = window.scrollY;

      if (currentScrollYPosition > threshold) {
        setScrollDir(true);
      } else {
        setScrollDir(false);
      }
    };

    const onScroll = () => window.requestAnimationFrame(updateScrollDirection);

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return scrollDir;
};
