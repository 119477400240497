import React from 'react';

interface Props {
  query: string;
  setQuery: Function;
}

export default function AdminSearchBar(props: Props) {
  return (
    <div className="w-full">
      <div className="pt-5 w-full flex justify-center">
        <input
          className="rounded-lg outline-none bg-slate-50 h-14 text-slate-700 text-center hover:shadow-lg transition-all duration-300 text-2xl w-[50%]"
          type="text"
          placeholder="Search"
          value={props.query}
          onChange={(e) => props.setQuery(e.target.value)}
        />
      </div>
    </div>
  );
}
