import React from 'react';

interface Props {
  setCurrentTab: Function;
  name: string;
  index: number;
}

const getSVGData = (index: number) => {
  switch (index) {
    case 0:
      // snacks: 0
      return (
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          className="fill-current text-[#6c3b17]"
        >
          <path d="M5.786 15.237c-.545-1.685-.222-3.742.897-5.571 1.605 3.433 4.177 5.993 7.673 7.637-1.823 1.123-3.877 1.454-5.561.921.043 2.092-.952 4.468-2.249 5.776l-.727-2.182-1.666-.211-.157-1.611-1.634-.181-.18-1.634-2.182-.727c1.316-1.271 3.71-2.196 5.786-2.217m11.484-.827c-.524.841-1.191 1.581-1.945 2.202-3.654-1.578-6.339-4.15-7.95-7.92.621-.764 1.371-1.43 2.197-1.951 1.605 3.446 4.18 6.017 7.698 7.669m-2.031-8.626c.02-2.075.946-4.47 2.215-5.784l.728 2.182 1.634.179.18 1.634 1.612.158.211 1.665 2.181.727c-1.307 1.297-3.682 2.292-5.775 2.249.452 1.427.283 3.119-.462 4.712-3.31-1.538-5.756-3.946-7.287-7.26 1.61-.759 3.322-.927 4.763-.462" />
        </svg>
      );
    case 1:
      // drinks: 1
      return (
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          className="fill-current text-[#6c3b17]"
        >
          <path d="M17 24h-10l-2-16h14l-2 16zm-6.25-5.5c.414 0 .75.336.75.75s-.336.75-.75.75-.75-.336-.75-.75.336-.75.75-.75zm3.25-2.5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-3.5-3c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm1.685-9l2.072-4 1.359.634-1.776 3.366h4.199c0 .922 1.092 1.618 1.961 1.618v1.382h-16v-1.382c.87 0 2-.697 2-1.618h6.185z" />
        </svg>
      );
    case 2:
      // pastries: 2
      return (
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          className="fill-current text-[#6c3b17]"
        >
          <path d="M9 19.5l-.012-4.5h2.012l1 4.5 1-4.5h2.037l-.037 4.5 2-4.5h3l-3.556 9h-8.888l-3.556-9h3l2 4.5zm10.941-5.5h-15.878s-1.059-.64-1.059-2.154c0-1.853 1.612-3.156 3.176-3.231.77 1.469 3.117 2.435 5.293 2.154-2.507-.558-5.692-3.718-3.9-6.21 1.272-1.77 4.473-1.486 3.9-4.559 2.131.589 3.43 2.167 3.176 4.308h.002c2.104-.101 3.867 1.3 3.173 4.307 1.754 0 3.176 1.447 3.176 3.231 0 1.328-1.051 2.148-1.059 2.154z" />
        </svg>
      );
    case 3:
      // hot drinks: 3
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          className="fill-current text-[#6c3b17] w-10"
        >
          <path d="M13 20h-7c-2.174-3.004-4-6.284-4-12h15c0 5.667-1.88 9.089-4 12zm5.119-10c-.057.701-.141 1.367-.252 2h1.549c-.449 1.29-1.5 2.478-2.299 2.914-.358 1.038-.787 1.981-1.26 2.852 3.275-1.143 5.847-4.509 6.143-7.766h-3.881zm-1.119 12h-15v2h15v-2zm-3.06-19.614c-.416 1.702-3.07 2.477-3.983 4.614-.088-1.846 1.107-3.031 1.75-3.93 1.045-1.465-.537-2.267-1.633-1.171-.188.187-.38.435-.573.756-.193-.322-.386-.57-.573-.757-1.089-1.09-2.664-.294-1.658 1.137.635.903 1.863 2.095 1.775 3.965-.914-2.137-3.567-2.912-3.984-4.614-.355-1.445.928-2.386 2.29-2.386.793 0 1.613.32 2.15 1.045.537-.725 1.357-1.045 2.15-1.045 1.362 0 2.644.941 2.289 2.386z" />
        </svg>
      );
    case 4:
      // breakfast: 4
      return (
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          className="fill-current text-[#6c3b17]"
        >
          <path d="M18.496 24h-.001c-.715 0-1.5-.569-1.5-1.5v-8.5s-1.172-.003-2.467 0c.802-6.996 3.103-14 4.66-14 .447 0 .804.357.807.851.01 1.395.003 16.612.001 21.649 0 .828-.672 1.5-1.5 1.5zm-11.505-12.449c0-.691-.433-.917-1.377-1.673-.697-.56-1.177-1.433-1.088-2.322.252-2.537.862-7.575.862-7.575h.6v6h1.003l.223-6h.607l.173 6h1.003l.242-6h.562l.199 6h1.003v-6h.549s.674 5.005.951 7.55c.098.902-.409 1.792-1.122 2.356-.949.751-1.381.967-1.381 1.669v10.925c0 .828-.673 1.5-1.505 1.5-.831 0-1.504-.672-1.504-1.5v-10.93z" />
        </svg>
      );
    case 5:
      // lunch: 5
      return (
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          className="fill-current text-[#6c3b17]"
        >
          <path d="M22.224 14.928c-.634.313-1.212.471-1.53 1.14-.371.781-.094 1.372.409 2.061.517.721-.22 1.133-1.103 1.153v-8.432l.29-.263.001.01c.428.154.503.353.539.81.073 1-.081 1.938 1.217 2.759.451.268.717.475.177.762m-18.756 4.415c-.116-.734-.103-1.066-.614-1.61-.318-.338-.605-.474-.982-.718-.534-.347-.398-.725.107-1.09.245-.171.503-.327.734-.518.604-.497.924-1.2.742-1.94-.11-.451-.349-.747-.602-1.134-.611-.936.014-1.657.852-1.777v-.001l.295.295v8.776c-.275.003-.485-.016-.532-.283m19.801-6.19c-.579-.392-.855-.372-.908-1.07-.066-1.078-.01-2.008-1.068-2.661h-.006c.677-1.03.863-2.165.674-3.182-.671-3.626-6.103-4.213-9.792-4.24-4.588-.009-10.094.43-10.142 4.907-.011 1 .234 1.724.652 2.407l-.01-.001c-1.061.512-1.609 1.476-1.592 2.329.015.809.345 1.231.761 1.871.22.336.292.468-.231.853-.896.622-1.616 1.061-1.607 2.217.01 1.059.663 1.428 1.49 1.961.522.348.414.461.489.989.15 1.071 1.003 1.643 2.141 1.607l.004-.018c.209.695.675.869 1.4.878h13.055c.878-.002 1.34-.349 1.428-1.219l.001.001c1.8-.043 2.689-1.013 2.766-2.071.06-.837-.279-1.162-.677-1.764-.133-.215-.043-.276.179-.389.956-.467 1.724-.785 1.724-1.959 0-.665-.247-1.092-.731-1.446" />
        </svg>
      );
    case 6:
      // specials: 6
      return (
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          className="fill-current text-[#6c3b17]"
        >
          <path d="M9 23h-6v-7.988c.517.217 1.134.384 1.938.484.274.034.524-.16.558-.434.034-.274-.16-.524-.434-.558-4.81-.603-5.062-5.307-5.062-6.004 0-3.587 2.913-6.5 6.5-6.5.788 0 1.543.141 2.242.397.82-.86 1.977-1.397 3.258-1.397s2.438.537 3.258 1.397c.699-.256 1.454-.397 2.242-.397 3.587 0 6.5 2.913 6.5 6.5 0 .691-.252 5.401-5.062 6.004-.274.034-.468.284-.434.558.034.274.284.468.558.434.804-.1 1.421-.267 1.938-.484v7.988h-6v-3.5c0-.311-.26-.5-.5-.5-.239 0-.5.189-.5.5v3.5h-4v-3.5c0-.311-.26-.5-.5-.5-.239 0-.5.189-.5.5v3.5z" />
        </svg>
      );
  }
};

export default function CategoryButton(props: Props) {
  return (
    <button
      className="bg-gradient-to-t from-[#DFC4BC] to-[#fefefe] shadow-inner p-2 rounded-lg text-[#6c3b17] text-sm active:scale-75 transition-all"
      onClick={() => {
        props.setCurrentTab(props.index);
      }}
    >
      <div className="flex flex-row justify-center items-center space-x-2">
        {getSVGData(props.index)}
        <p className={`font-semibold`}>{props.name.toUpperCase()}</p>
      </div>
    </button>
  );
}
