import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../App';
import { Food } from '../interfaces/Food';

interface Props {
  index: number;
  food: Food;
  setUpdateSum: Function;
  updateSum: boolean;
}

export default function CartItem(props: Props) {
  const [quantity, setQuantity] = useState<number>(1);
  const [bounceToggle, setBounceToggle] = useState<boolean>(false);
  const [inverseBounceToggle, setInverseBounceToggle] =
    useState<boolean>(false);

  const { updateFoodQuantity, setTmpFood } = useContext(AppContext);

  // cents to dollar
  const convertCentsToDollar = (amount: string): number => {
    return parseInt(amount, 10) / 100;
  };

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const animatePrice = async (isBig: boolean) => {
    if (isBig) {
      setBounceToggle(true);
      await timeout(100);
      setBounceToggle(false);
    } else {
      setInverseBounceToggle(true);
      await timeout(100);
      setInverseBounceToggle(false);
    }
  };

  //
  return (
    <div
      className={`card card-compact rounded-lg w-full bg-[#fefefe]`}
      key={props.index}
    >
      <div className="card-body flex-row justify-between items-center">
        {/* image */}
        <div className="flex justify-center items-center w-[50%] rounded-lg">
          <Link
            to="/customize"
            onClick={() => {
              props.food.isEdit = true;
              setTmpFood!(props.food);
            }}
          >
            <img
              src={props.food.imgURL}
              alt="food"
              className="rounded-lg aspect-auto"
            />
          </Link>
        </div>

        <div className="w-full pl-5 text-[#6c3b17]">
          <h2 className={'font-semibold text-lg'}>{props.food.name!}</h2>
          {/* price */}
          <div className="flex flex-row gap-2 items-center">
            <div>
              <p className={`font-semibold text-lg`}>
                $
                {convertCentsToDollar(
                  (
                    parseInt(props!.food!.price!) *
                    (props.food.quantity ? props.food.quantity : 1)
                  ).toString()
                )}
              </p>
            </div>
            <div>
              <p className="font-medium text-sm">
                x{props.food.quantity ? props.food.quantity : '1'}
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-2 items-center w-full">
            <div className="w-full">
              {props.food.customization &&
                props.food.customization.map((food: Food, index: number) => {
                  return (
                    <div className="flex flex-row w-full pr-5">
                      <p>{food.name}</p>
                      <p className="text-right">
                        {food.price! === '0'
                          ? ''
                          : '$' +
                            convertCentsToDollar(
                              (parseInt(food.price!) * quantity).toString()
                            )}
                        {food.quantity ? ' x' + food.quantity : ''}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {/* adder */}
        <div className="flex flex-col items-center gap-6">
          {/* plus */}
          <div
            onClick={() => {
              setQuantity(quantity + 1);
              animatePrice(true);
              const newFood = props!.food;
              newFood.quantity = quantity + 1;
              updateFoodQuantity!(newFood);
              props.setUpdateSum(true);
            }}
            className={`${bounceToggle ? 'scale-150' : 'scale-100'}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              className="fill-current text-[#6c3b17]"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z" />
            </svg>
          </div>

          {/* quantity */}
          {/* <div>
            <p className="text-[#6c3b17] text-xl font-medium">
              {quantity || props.food.quantity}
            </p>
          </div> */}

          {/* minus */}
          <div
            onClick={() => {
              if (quantity - 1 <= 0) {
                setQuantity(0);
              } else {
                setQuantity(quantity - 1);
              }
              animatePrice(false);
              const newFood = props!.food;
              newFood.quantity = quantity - 1;
              updateFoodQuantity!(newFood);
              props.setUpdateSum(true);
            }}
            className={`${inverseBounceToggle ? 'scale-50' : 'scale-100'}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              className="fill-current text-[#6c3b17]"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-12v-2h12v2z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
