import { useEffect, useState } from 'react';

export default function CommentBox() {
  const [placeholder, setPlaceholder] = useState<string>(
    'Tell the chef something about your order...'
  );
  const [totalLength, setTotalLength] = useState<number>(placeholder.length);

  const characterLimit: number = 201;

  // function for saving placeholder to local storage
  const saveToLocalStorage = () => {
    localStorage.setItem('comment', placeholder);
  };

  useEffect(() => {
    if (placeholder.length > characterLimit) {
      setPlaceholder(placeholder.slice(0, characterLimit));
    }
  }, [setPlaceholder, placeholder]);

  return (
    <>
      <div className="relative -left-[40%] text-sm font-medium text-primary">
        {placeholder.length}/{characterLimit}
      </div>
      <div className="w-[90%] text-[#6c3b17]">
        <textarea
          className="w-full text-center border-none outline-none resize-none font-medium text-xl"
          aria-multiline
          maxLength={characterLimit}
          onChange={(e) => {
            setPlaceholder(e.target.value);
            setTotalLength(e.target.value.length);
            saveToLocalStorage();
          }}
          onClick={() => {
            if (placeholder === 'Tell the chef something about your order...') {
              setPlaceholder('');
            }
          }}
          value={placeholder}
          onBlur={() => {
            if (placeholder === '') {
              setPlaceholder('Tell the chef something about your order...');
            }
            saveToLocalStorage();
          }}
          rows={totalLength * 0.4 > 30 ? 6 : 3}
        ></textarea>
      </div>
    </>
  );
}
