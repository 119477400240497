import React from 'react';
import Logo from '../imgs/logo.png';

export default function ContactBar() {
  return (
    <div className="px-5">
      <div className="navbar bg-transparent justify-center rounded-l">
        {/* logo(written version) */}
        {/* actual logo */}
        <img
          className="max-w-[45%] py-4 bg-white rounded-full"
          src={Logo}
          alt=""
        />

        {/* contact store */}
        {/* <a
          href="tel:+19082472933"
          className="flex flex-col w-full items-end text-xl text-[#6c3b17] font-semibold"
        >
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            className="fill-current text-[#6c3b17] scale-150"
          >
            <path d="M16 22.621l-3.521-6.795c-.007.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.082-1.026-3.492-6.817-2.106 1.039c-1.622.845-2.298 2.627-2.289 4.843.027 6.902 6.711 18.013 12.212 18.117.575.011 1.137-.098 1.677-.345.121-.055 2.102-1.029 2.11-1.033zm4-5.621h-1v-13h1v13zm-2-2h-1v-9h1v9zm4-1h-1v-7h1v7zm-6-1h-1v-5h1v5zm-2-1h-1v-3h1v3zm10 0h-1v-3h1v3zm-12-1h-1v-1h1v1z" />
          </svg>
        </a> */}
      </div>
    </div>
  );
}
