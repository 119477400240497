import { useState, createContext, useEffect } from 'react';

// react router import
import { Route, Routes } from 'react-router-dom';

// components
import { AppMenu } from './@types/ContextTypes';
import { Food } from './interfaces/Food';
import Admin from './pages/Admin';

// pages component
import CartPage from './pages/CartPage';
import CustomizePage from './pages/CustomizePage';
import Home from './pages/Home';
import OrderPaid from './pages/OrderPaid';
import Orders from './pages/Orders';

export const AppContext = createContext<AppMenu>({});

function App() {
  const [cart, setCart] = useState<Food[]>(
    JSON.parse(localStorage.getItem('cart') || '[]')
  );

  const [tmpFood, setTmpFood] = useState<Food>({ id: '-1' });

  // cents to dollar
  const convertCentsToDollar = (amount: string): number => {
    return parseInt(amount, 10) / 100;
  };

  // add food
  const addFood = (food: Food) => {
    setCart([...cart, { ...food }]);
  };

  // clear cart
  const clearCart = () => {
    setCart([]);
  };

  // remove food
  const removeFood = (food: Food) => {
    setCart(cart.filter((_food) => _food.id !== food.id));
  };

  // update quantity
  // q. help me understand what the function below is doing
  // a. it's updating the quantity of the food
  // q. can you explain like im five and further elaborate on each line of code
  // a. sure, so the function takes in a food object and then we try to find the index of the food object in the cart array
  const updateFoodQuantity = (food: Food) => {
    try {
      let index = -1;
      const tmp_state = [...cart];
      const tmp_object = tmp_state.find((item, i) => {
        if (item.id === food.id && item.name === food.name) {
          index = i;
          return item;
        } else {
          return null;
        }
      });

      // update quantity
      // if (tmp_object) {
      //   if (tmp_object.quantity) {
      //     tmp_object.quantity = food.quantity;
      //   }
      // }

      if (food.quantity! <= 0) {
        removeFood(food);
      } else {
        tmp_state[index] = food;
        setCart([...tmp_state]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // sum food
  const sumFood = () => {
    let total = 0;
    cart.length > 0 &&
      cart.forEach((item: Food) => {
        total += parseFloat(item!.price!) * (item!.quantity! || 1);

        if (item.customization) {
          item.customization.forEach((option: Food) => {
            total += parseFloat(option!.price!);
          });
        }
      });
    return convertCentsToDollar(total.toFixed(2).toString());
  };

  // update or create local storage for cart
  const updateLocalStorage = () => {
    localStorage.setItem('cart', JSON.stringify(cart));
  };

  useEffect(() => {
    updateLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  return (
    <AppContext.Provider
      value={{
        cart: cart,
        removeFood: removeFood,
        addFood: addFood,
        sumFood: sumFood,
        tmpFood: tmpFood,
        setTmpFood: setTmpFood,
        updateFoodQuantity: updateFoodQuantity,
        clearCart: clearCart,
      }}
    >
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/customize" element={<CustomizePage />}></Route>
        <Route path="/cart" element={<CartPage />}></Route>
        <Route path="/cart/order/success" element={<OrderPaid />}></Route>
        <Route path="/v2/ad/dash" element={<Admin />}></Route>
        <Route path="/v2/orders" element={<Orders />}></Route>
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
