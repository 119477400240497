import { useContext, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { json, useSearchParams } from 'react-router-dom';
import { AppContext } from '../App';
import OrderProcessing from '../components/OrderProcessing';
import { Food } from '../interfaces/Food';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

export default function OrderPaid() {
  const [searchParams] = useSearchParams();
  const [cart, setCart] = useState<Food[]>([]);
  const [orderId, setOrderId] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [prepTime, setPrepTime] = useState<number>(1000 * 60 * 10); // 10 minutes
  const { clearCart } = useContext(AppContext);

  const clearLocalAndContext = () => {
    // clear cart in context
    clearCart && clearCart();

    // clear localStorage
    localStorage.clear();
  };

  const cleanData = (data: any) => {
    let _data: Food[] = data;

    _data = _data.map((item) => {
      if (item.customization) {
        return {
          name: item.name || 'No Name',
          price: item.price || '0',
          quantity: item.quantity || 1,
          customization: item.customization.map((item) => {
            return {
              name: item.name || 'No Name',
              price: item.price || '0',
              quantity: item.quantity || 1,
            };
          }),
        };
      } else {
        return {
          name: item.name,
          price: item.price,
          quantity: item.quantity,
        };
      }
    });

    const newData: { [key: string]: any } = {};

    _data.forEach((newItem, index) => {
      newData[`item${index}`] = newItem;
    });

    return newData;
  };

  // api call
  const postOrder = async () => {
    console.log('posting order');
    let headersList = {
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    let bodyContent = JSON.stringify({
      cart: cleanData(JSON.parse(localStorage.getItem('cart') || '[]')),
      id: localStorage.getItem('orderId'),
      comment: localStorage.getItem('comment'),
      date: new Date().toISOString(),
    });

    let response = await fetch(
      'https://r1isfpy1tg.execute-api.us-east-1.amazonaws.com/prod/orders',
      {
        method: 'POST',
        body: bodyContent,
        headers: headersList,
      }
    );

    let data = await response.text();
  };

  // determine prepTime based on cart length
  const determinePrepTime = () => {
    let time: number = 0;
    const _cart: Food[] = cart;
    if (_cart.length > 0) {
      // loop through cart
      _cart.forEach((item) => {
        // check item type
        if (
          item.type &&
          (item.type === 'breakfast' ||
            item.type === 'special' ||
            item.type === 'lunch')
        ) {
          time += Math.round(1000 * 60 * 2.5); // 10 minutes
        }
      });
    }
    setPrepTime(1000 * 60 * 10 + time);
  };

  useEffect(() => {
    // wait for 5 seconds before posting order
    // get cart, orderId, and comment from localStorage
    setCart(JSON.parse(localStorage.getItem('cart') || '[]'));
    setOrderId(localStorage.getItem('orderId') || '');
    setComment(localStorage.getItem('comment') || '');
    determinePrepTime();
    setTimeout(() => {
      if (
        localStorage.getItem('orderId') !== '' &&
        JSON.parse(localStorage.getItem('cart') || '[]').length > 0
      ) {
        postOrder();
      }
    }, 700);
    // clear localStorage and context in 1 second
    setTimeout(() => {
      clearLocalAndContext();
    }, 2400);
  }, []);

  return (
    <div
      className="bg-transparent min-h-screen py-6 px-5 flex flex-col justify-start items-center space-y-6"
      style={{
        backgroundColor: '#ffffff',
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%235a380a' fill-opacity='0.025'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      }}
    >
      <div className="flex flex-col gap-2 justify-center items-center md:max-w-5xl">
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          gravity={0.055}
          numberOfPieces={1000}
          recycle={false}
        />
        {cart.length > 0 && (
          <FlipClockCountdown
            to={new Date().getTime() + prepTime}
            renderMap={[false, false, true, true]}
            digitBlockStyle={{
              width: 40,
              height: 60,
              fontSize: 30,
              color: '#6c3b17',
              backgroundColor: 'white',
            }}
            dividerStyle={{ color: 'white', height: 1 }}
            separatorStyle={{ color: '#6c3b17', size: '6px' }}
            duration={0.5}
          />
        )}
        <OrderProcessing cart={cart} cleartContext={clearLocalAndContext} />
      </div>
    </div>
  );
}
