import { useEffect, useState } from 'react';
import { Food } from '../interfaces/Food';
import AdminLabelInput from './AdminLabelInput';
import { updateFoodItem } from '../api/MenuCRUDService';

interface Props {
  food: Food;
  setEditFood: Function;
}

interface State {
  food: Food;
}

export default function AdminEditCard(props: Props) {
  const foodInfo: any = [];

  const [state, setState] = useState<State>({
    food: {
      id: '',
      type: '',
    },
  });

  const handleInputUpdate = (updatedValues: { [key: string]: any }) => {
    setState((prevState) => ({
      ...prevState,
      food: {
        ...prevState.food,
        ...updatedValues,
      },
    }));
  };

  for (let key in props.food) {
    // if string
    if (
      typeof props.food[key] === 'string' ||
      typeof props.food[key] === 'number'
    ) {
      foodInfo.push(
        <AdminLabelInput
          key={key}
          foodValue={props.food[key]}
          labelName={key}
          handleInputUpdate={handleInputUpdate}
        />
      );
    }
  }

  for (let key in props.food) {
    // if array
    if (Array.isArray(props.food[key])) {
      const foodInfoList: any = [];

      // map through array
      props.food[key].map((food: Food, index: number) => {
        foodInfoList.push(
          <AdminLabelInput
            key={key + index}
            foodValue={food}
            labelName={key}
            handleInputUpdate={handleInputUpdate}
          />
        );
      });

      foodInfo.push(
        <div key={key} className="flex flex-col gap-2">
          <div className="flex flex-row">
            <p className="text-white font-bold bg-red-400 rounded-lg pl-2 text-xl">
              {key}
            </p>
            {/* plus button svg */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-2 mt-1 text-[#6c3b17] cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  food: {
                    ...prevState.food,
                    [key]: [
                      ...prevState.food[key],
                      {
                        name: '',
                        type: prevState.food[key][0].type,
                        price: 0,
                      },
                    ],
                  },
                }));
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          </div>
          {foodInfoList}
        </div>
      );
    }
  }

  useEffect(() => {
    // set edit food with state.food
    props.setEditFood(state.food);
  }, [state]);

  useEffect(() => {
    // update state.food
    setState((prevState) => ({
      ...prevState,
      food: props.food,
    }));
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div
        className="z-40 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50"
        onClick={() => props.setEditFood({ id: '-1' })}
      ></div>
      <div className="bg-white w-[90%] h-[70%] lg:w-1/2 lg:h-1/2 rounded-lg z-50">
        <div className="flex flex-col justify-between h-full overflow-y-auto">
          {/* food info */}
          <div className="flex flex-col gap-5 bg-gray-200 p-4 rounded-lg">
            {foodInfo}
          </div>

          {/* buttons */}
          <div className="flex justify-center items-center py-4">
            <button
              className="bg-slate-500 text-white rounded-lg py-2 px-4 mr-4"
              onClick={() => props.setEditFood({ id: '-1' })}
            >
              cancel
            </button>
            <button
              className="bg-slate-500 text-white rounded-lg py-2 px-4"
              onClick={() => {
                // update food item with state.food
                updateFoodItem(state.food.type!, state.food.id!, state.food);
                // close edit card
                props.setEditFood({ id: '-1' });
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
