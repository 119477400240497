import { useState } from 'react';
import { useGetScrollPosition } from '../hooks/useGetScrollPosition';
import { useScrollLocation } from '../hooks/useScrollLocation';
import CategoryButton from './CategoryButton';

interface Props {
  setCurrentTab: Function;
  scrollPos: number;
}

export default function CategoryTabBar(props: Props) {
  const [buttonStates, setButtonStates] = useState<string[]>([
    'scale-75',
    'scale-100',
    'scale-100',
    'scale-100',
    'scale-100',
    'scale-100',
    'scale-100',
  ]);

  const scrollLoc = useScrollLocation();

  // reset scale
  const resetScaleButtonStates = () => {
    setButtonStates(buttonStates);
  };
  return (
    <div
      className={`${
        scrollLoc ? 'px-4' : 'px-0'
      } transition-all duration-300 flex flex-col gap-2`}
    >
      <div
        className={`bg-white rounded-lg w-full px-2 py-3 ${
          scrollLoc ? 'shadow-lg border-b-[1px] border-t-[1px]' : 'border-none'
        }`}
      >
        <div className="carousel gap-3 h-14">
          {/* breakfast: 4 */}
          <CategoryButton
            name="Breakfast"
            index={4}
            setCurrentTab={props.setCurrentTab}
          />

          {/* lunch: 5 */}
          <CategoryButton
            name="Lunch"
            index={5}
            setCurrentTab={props.setCurrentTab}
          />

          {/* hot drinks: 3 */}
          <CategoryButton
            name="Hot Drinks"
            index={3}
            setCurrentTab={props.setCurrentTab}
          />

          {/* specials: 6 */}
          <CategoryButton
            name="Specials"
            index={6}
            setCurrentTab={props.setCurrentTab}
          />

          {/* snacks: 0 */}
          <CategoryButton
            name="Snacks"
            index={0}
            setCurrentTab={props.setCurrentTab}
          />

          {/* drinks: 1 */}
          <CategoryButton
            name="Drinks"
            index={1}
            setCurrentTab={props.setCurrentTab}
          />

          {/* pastries: 2 */}
          <CategoryButton
            name="Pastries"
            index={2}
            setCurrentTab={props.setCurrentTab}
          />
        </div>
      </div>
    </div>
  );
}
