import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import type { Food } from '../interfaces/Food';

interface Props {
  option: Food[];
  name: string;
  food?: Food;
  setFood?: Function;
}

export default function CheeseOptions(props: Props) {
  // context
  const { tmpFood } = useContext(AppContext);

  // uncheck action
  const uncheckAction = (food: Food) => {
    // check if more than one cheese is in customization
    const totalCheese =
      props.food!.customization?.filter(
        (_food: Food) => _food.type === 'cheese'
      )?.length || 0;

    if (totalCheese > 1 && food.price === '0') {
      // if so, remove the cheese from customization
      const _tmpFood = {
        ...props.food,
        customization: props.food?.customization?.filter(
          (_food) => _food.name !== food.name
        ),
      };

      // find and update the price of any but only one cheese to 0
      let foundCheese = false;
      const _tmpFood2 = {
        ..._tmpFood,
        customization: _tmpFood?.customization?.map((_food) => {
          if (!foundCheese && _food.type === 'cheese' && _food.price !== '0') {
            // Cheese is free
            _food.price = '0';
            foundCheese = true;
          }
          return _food;
        }),
      };

      // update
      props.setFood!(_tmpFood2);
    } else if (totalCheese === 1 && food.price === '0') {
      // if not, remove the cheese from customization
      const _tmpFood = {
        ...props.food,
        customization: props.food?.customization?.filter(
          (_food) => _food.name !== food.name
        ),
        hasFreeCheese: false,
      };

      // update
      props.setFood!(_tmpFood);
    } else {
      // if not, remove the cheese from customization
      const _tmpFood = {
        ...props.food,
        customization: props.food?.customization?.filter(
          (_food) => _food.name !== food.name
        ),
      };

      // update
      props.setFood!(_tmpFood);
    }
  };

  // check action
  const checkAction = (food: Food) => {
    // check if tmpFood has free cheese
    if (props.food?.hasFreeCheese) {
      // if so, add the cheese to customization
      const _tmpFood = {
        ...props.food,
        customization: [...props.food?.customization!, food],
      };
      props.setFood!(_tmpFood);
    } else {
      // if not, add the cheese to customization and set hasFreeCheese to true
      const _tmpFood = {
        ...props.food,
        customization: [...props.food?.customization!, { ...food, price: '0' }],
        hasFreeCheese: true,
      };
      props.setFood!(_tmpFood);
    }
  };

  // cents to dollar
  const convertCentsToDollar = (item: Food): number => {
    return parseInt(item.price!, 10) / 100;
  };

  // find item in customization array and return the item if found, otherwise return false
  const findItemInCustomization = (item: Food): Food => {
    return (
      props.food?.customization?.find((_item) => _item.name === item.name) ||
      item
    );
  };

  return (
    <div className="collapse collapse-arrow text-slate-700 w-full rounded-lg shadow font-semibold text-lg">
      <input type="checkbox" className="peer" />
      <div className="collapse-title bg-white text-xl">
        {props.name?.toUpperCase()}
      </div>
      <div className="collapse-content bg-white flex flex-col justify-between px-6 space-y-3">
        {props.option &&
          props.option.map((item: Food, index: any) => {
            // modify item price with
            const modifiedItem: Food = { ...findItemInCustomization(item) };
            return (
              <div
                className="flex flex-row justify-between items-center w-full"
                key={index}
              >
                <p>{item.name?.toUpperCase()}</p>
                <div className="flex flex-row space-x-4">
                  <p className="text-sm">
                    {props.food?.hasFreeCheese
                      ? '$' + convertCentsToDollar(modifiedItem).toFixed(2)
                      : ''}
                  </p>
                  <input
                    type="checkbox"
                    className="checkbox bg-gray-100"
                    onChange={(event) => {
                      if (event.target.checked) {
                        checkAction(modifiedItem);
                      } else if (!event.target.checked) {
                        uncheckAction(modifiedItem);
                      }
                    }}
                    checked={
                      props.food?.customization?.find(
                        (_item) => _item.name === modifiedItem.name
                      )
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
