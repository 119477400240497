import { useEffect, useState } from 'react';

export const useGetScrollPosition = () => {
  const threshold = 100;
  const [scrollPos, setScrollPos] = useState<number>(0);

  useEffect(() => {
    const updateScrollDirection = () => {
      setScrollPos(window.scrollY);
    };

    const onScroll = () => window.requestAnimationFrame(updateScrollDirection);

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return scrollPos;
};
