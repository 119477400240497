import { useContext, useState } from 'react';
import { AppContext } from '../App';
import type { Food } from '../interfaces/Food';
import Counter from './Counter';

interface Props {
  option: Food[];
  name: string;
}

export default function IncrementCounter(props: Props) {
  // context
  const { tmpFood, setTmpFood } = useContext(AppContext);

  // tracking total sugars
  const [totalSugars, setTotalSugars] = useState<number>(0);

  // cents to dollar
  const convertCentsToDollar = (amount: string): number => {
    return parseInt(amount, 10) / 100;
  };
  return (
    <div className="collapse collapse-arrow text-slate-700 w-full rounded-lg shadow font-semibold text-lg">
      <input type="checkbox" className="peer" />
      <div className="collapse-title bg-white text-xl flex flex-row w-full justify-between items-center">
        {props.name?.toUpperCase()}{' '}
        <span className="font-normal text-xs">
          {totalSugars >= 8 ? 'max sugars allowed' : ''}
        </span>
      </div>
      <div className="collapse-content bg-white flex flex-col justify-between px-6 space-y-3">
        {props.option &&
          props.option.map((item: Food, index: any) => {
            return (
              <div className="flex flex-row justify-between items-center w-full">
                <p>{item.name?.toUpperCase()}</p>
                <div className="flex flex-row space-x-4">
                  <p>
                    {item.price !== '0'
                      ? '$' + convertCentsToDollar(item.price!).toFixed(2)
                      : ''}
                  </p>
                  <Counter
                    totalSugars={totalSugars}
                    setTotalSugars={setTotalSugars}
                    item={item}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
