import { useEffect, useState } from 'react';

interface WebSocketHandlers {
  addMessageListener: (listener: (event: MessageEvent) => void) => void;
  removeMessageListener: (listener: (event: MessageEvent) => void) => void;
}

export const useWebSocket = (url: string): WebSocketHandlers | null => {
  const [socket, setSocket] = useState<WebSocket | null>(null);

  const addMessageListener = (listener: (event: MessageEvent) => void) => {
    if (socket !== null) {
      socket.addEventListener('message', listener);
    }
  };

  const removeMessageListener = (listener: (event: MessageEvent) => void) => {
    if (socket !== null) {
      socket.removeEventListener('message', listener);
    }
  };

  useEffect(() => {
    const newSocket = new WebSocket(url);
    setSocket(newSocket);

    return () => {
      if (newSocket.readyState === WebSocket.OPEN) {
        newSocket.close();
      }
    };
  }, [url]);

  return socket === null
    ? null
    : {
        addMessageListener,
        removeMessageListener,
      };
};
