import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../App';
import DropdownOptions from '../components/DropdownOptions';
import { nanoid } from 'nanoid';
import IncrementCounter from '../components/IncrementCounter';
import CheeseOptions from '../components/CheeseOptions';
import { Food } from '../interfaces/Food';

export default function CustomizePage() {
  const navigate = useNavigate();
  const { addFood, tmpFood, updateFoodQuantity, setTmpFood } =
    useContext(AppContext);

  const [food, setFood] = useState<Food>(tmpFood ? tmpFood : ({} as Food));

  // update tmpFood when food changes
  useEffect(() => {
    setTmpFood!(food);
  }, [food, setTmpFood]);

  // q. explain the code below
  // a. so the code below is a hook that runs when the component mounts
  // q. what does it do?
  // a. it adds an event listener to the window object
  // q. what does the event listener do?
  // a. it checks if the user clicks the back button and if the food is being edited then it updates the quantity of the food in the cart and then sets the food to not being edited anymore and then navigates to the cart page again so that the user can see the updated quantity
  // q. what is [tmpFood, updateFoodQuantity] doing?
  // a. it's a dependency array, so it tells the hook to run when the values in the array change and since tmpFood and updateFoodQuantity are the only values that change in this component, it only runs when those values change
  // q. what is `window.onpopstate` doing?
  // a. it's an event listener that listens for the popstate event which is triggered when the user clicks the back button or forward button in the browser and then it runs the function that is assigned to it.
  // q. so should I just remove my dependencies since I only need to assign the function at the creation?
  // a. yes, you can remove the dependency array and it will still work
  useEffect(() => {
    window.onpopstate = () => {
      if (tmpFood!.isEdit) {
        tmpFood!.isEdit = false;
        updateFoodQuantity!(tmpFood!);
      }
    };
  });

  return (
    <>
      <div
        className="bg-transparent min-h-screen py-6 px-5 flex flex-col justify-start items-center space-y-6"
        style={{
          backgroundColor: '#ffffff',
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%235a380a' fill-opacity='0.025'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        }}
      >
        <div className="flex flex-col gap-2 justify-center items-center md:max-w-5xl">
          {/* image */}
          <div className="flex justify-center w-full">
            <img className="rounded-lg w-[50%]" src={food.imgURL} alt="" />
          </div>

          {/* item name */}
          <div className="flex flex-col justify-center items-center gap-2 text-center">
            <h1 className="font-semibold text-xl text-[#6c3b17]">
              {food.name?.toUpperCase()}
            </h1>
            <p className="text-[#776254] text-xs max-w-[65%] font-medium">
              {food.description?.toUpperCase()}
            </p>
          </div>

          {/* included collapse */}

          {/* extra collapse */}

          {/* quantity */}

          {/* included */}
          <div className="flex flex-col items-center space-y-6 w-full">
            {/* needs fixing, shouldn't be syrup lol */}
            {/* {food.ingredients && food.ingredients.length > 0 ? (
              <DropdownOptions
                name="Ingredients"
                option={food.ingredients}
                food={food}
                setFood={setFood}
              />
            ) : (
              <></>
            )} */}
            {food.cheeseOption && food.cheeseOption.length > 0 ? (
              <CheeseOptions
                name="Cheese"
                option={food.cheeseOption!}
                food={food}
                setFood={setFood}
              />
            ) : (
              <></>
            )}
            {food.sauce && food.sauce.length > 0 ? (
              <DropdownOptions
                name="Sauce"
                option={food.sauce!}
                food={food}
                setFood={setFood}
              />
            ) : (
              <></>
            )}
            {food.tea && food.tea.length > 0 ? (
              <DropdownOptions
                name="Tea"
                option={food.tea!}
                food={food}
                setFood={setFood}
              />
            ) : (
              <></>
            )}
            {food.coffeeStyle && food.coffeeStyle.length > 0 ? (
              <DropdownOptions
                name="Style"
                option={food.coffeeStyle!}
                food={food}
                setFood={setFood}
              />
            ) : (
              <></>
            )}
            {food.milk && food.milk.length > 0 ? (
              <DropdownOptions
                name="Milk"
                option={food.milk!}
                food={food}
                setFood={setFood}
              />
            ) : (
              <></>
            )}
            {food.sizes && food.sizes.length > 0 ? (
              <DropdownOptions
                name="Size"
                option={food.sizes!}
                food={food}
                setFood={setFood}
              />
            ) : (
              <></>
            )}
            {food.sugars && food.sugars.length > 0 ? (
              <IncrementCounter name="Sugar" option={food.sugars!} />
            ) : (
              <></>
            )}
            {/* {tmpFood!.baseOption ? (
            <DropdownOptions possiblyChecked={tmpFood!.customization} name="Size" option={tmpFood!.baseOption!} />
          ) : (
            <></>
          )} */}
            {food.meatOption && food.meatOption.length > 0 ? (
              <DropdownOptions
                name="Meat"
                option={food.meatOption!}
                food={food}
                setFood={setFood}
              />
            ) : (
              <></>
            )}
            {food.veggieOptions && food.veggieOptions.length > 0 ? (
              <DropdownOptions
                name="Veggie"
                option={food.veggieOptions!}
                food={food}
                setFood={setFood}
              />
            ) : (
              <></>
            )}
            {food.extra && food.extra.length > 0 ? (
              <DropdownOptions
                name="Extra"
                option={food.extra}
                food={food}
                setFood={setFood}
              />
            ) : (
              <></>
            )}
          </div>

          {/* buttons */}
          <div className="flex flex-row justify-center items-center pt-6 gap-5">
            <button
              className="btn bg-transparent border-none hover:bg-transparent active:bg-transparent text-red-400 active:text-red-700"
              onClick={() => {
                if (food.isEdit) {
                  food.isEdit = false;
                  updateFoodQuantity!(food);
                  navigate(-1);
                } else {
                  navigate(-1);
                }
              }}
            >
              cancel
            </button>
            <button
              className="btn hover:border-none hover:bg-white border-[#6c3b17] border-[1px] bg-white text-[#6c3b17]"
              onClick={() => {
                // get options
                if (food.isEdit) {
                  food.isEdit = false;
                  updateFoodQuantity!(tmpFood);
                  navigate(-1);
                } else {
                  food.id = nanoid();
                  addFood!(tmpFood);
                  navigate(-1);
                }
              }}
            >
              {food.isEdit ? 'update item' : 'add to cart'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
