import { useEffect, useState } from 'react';
import { itemToData } from 'dynamo-converters';
import { useWebSocket } from '../hooks/useWebsocket';
import { Order } from '../interfaces/Item';
import { OrderCard } from '../components/OrderCard';
import { getRandomTagColor } from '../utils/ColorService';

function Orders() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [uniqueOrders, setUniqueOrders] = useState<Order[]>([]);

  // get orders with fetch
  const getOrders = async () => {
    const res = await fetch(
      'https://r1isfpy1tg.execute-api.us-east-1.amazonaws.com/prod/orders'
    );
    const data = await res.json();

    // remove any data with the attribute date that isn't today
    const today = new Date().toISOString().slice(0, 10);
    const filteredData = data.filter((item: any) => {
      return (
        item.date.slice(0, 10) === today &&
        !(item.status && item.status !== 'finished')
      );
    });

    setOrders(filteredData);
  };

  // update order with status finished
  const updateOrder = async (id: string) => {
    const res = await fetch(
      `https://r1isfpy1tg.execute-api.us-east-1.amazonaws.com/prod/orders`,
      {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          status: 'finished',
        }),
      }
    );
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    // on orders change check for duplicates by id and remove them and update uniqueOrders
    const uniqueOrders = orders.filter(
      (order, index, self) => index === self.findIndex((o) => o.id === order.id)
    );
    setUniqueOrders(uniqueOrders);
  }, [orders]);

  const socketHandlers = useWebSocket(
    'wss://f1cyz2amdk.execute-api.us-east-1.amazonaws.com/prod'
  )?.addMessageListener((message: any) => {
    const messageData = JSON.parse(message.data);

    // create an order object
    const order: any = {
      id: messageData.orders.data.id.S || '',
      comments: messageData.orders.data.comments
        ? messageData.orders.data.comments.S
        : '',
      firstName: messageData.orders.data.fistName
        ? messageData.orders.data.fistName.S
        : '',
      lastName: messageData.orders.data.lastName
        ? messageData.orders.data.lastName.S
        : '',
      phone: messageData.orders.data.phone
        ? messageData.orders.data.phone.S
        : '',
      email: messageData.orders.data.email
        ? messageData.orders.data.email.S
        : '',
      date: messageData.orders.data.date ? messageData.orders.data.date.S : '',
      cart: itemToData(messageData.orders.data.cart.M) || {},
    };

    setOrders([...orders, order]);
  });

  return (
    <div
      className="bg-[#fefefe] min-h-screen pb-2 lg:flex lg:justify-center h-full w-full"
      style={{
        backgroundColor: '#ffffff',
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%235a380a' fill-opacity='0.025'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      }}
    >
      {orders.length > 0 ? (
        <div className="flex md:flex-row flex-col gap-4 flex-wrap items-stretch">
          {orders.map((order, index) => {
            if (!order.status) {
              return (
                <div
                  key={index}
                  className="bg-white rounded-md shadow p-4 flex flex-col gap-2"
                >
                  <div
                    className={`bg-blue-400 text-white font-bold px-4 py-2 rounded-full mb-4`}
                  >
                    {order.id}
                  </div>
                  <div className="text-gray-500 text-sm">
                    <span className="font-bold">Name:</span>{' '}
                    {`${order.firstName} ${order.lastName}`}
                  </div>
                  <div className="text-gray-500 text-sm">
                    <span className="font-bold">Phone:</span> {order.phone}
                  </div>
                  <div className="text-gray-500 text-sm">
                    <span className="font-bold">Email:</span> {order.email}
                  </div>
                  <div className="text-gray-500 text-sm">
                    <span className="font-bold">Comment:</span> {order.comment}
                  </div>
                  {Object.entries(order.cart).map(([key, value], index) => (
                    <OrderCard
                      key={index}
                      orderNumber={order.id}
                      item={value}
                      tagColor="bg-blue-400"
                    />
                  ))}
                  {/* {order.comments && (
                    <div className="text-gray-500 text-sm mt-2">
                      <span className="font-bold">Comments:</span>{' '}
                      {order.comments}
                    </div>
                  )} */}
                  <button
                    className="bg-green-500 hover:bg-green-600 text-white font-bold px-4 py-2 rounded"
                    onClick={() => {
                      updateOrder(order.id);
                      setOrders(
                        orders.map((o) => {
                          if (o.id === order.id) {
                            o.status = 'finished';
                          }
                          return o;
                        })
                      );
                    }}
                  >
                    Finished
                  </button>
                </div>
              );
            }
          })}
        </div>
      ) : (
        <div className="flex justify-center items-center h-full">
          <h2 className="text-2xl font-bold text-gray-500">No orders found.</h2>
        </div>
      )}
    </div>
  );
}

export default Orders;
