import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import PaidItem from './PaidItem';
import { Food } from '../interfaces/Food';

const messageVariants = {
  initial: { opacity: 0, y: -50 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const dotsVariants = {
  animate: { opacity: 1, transition: { duration: 0.5, yoyo: Infinity } },
};

interface Props {
  cart: Food[];
  cleartContext: Function;
}

export default function OrderProcessing(props: Props) {
  const [message, setMessage] = useState('Thank you for your order!');
  const [dots, setDots] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((dots) => {
        if (dots.length === 3) {
          return '';
        } else {
          return dots + '.';
        }
      });
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <motion.div
      className="bg-transparent min-h-screen py-6 px-5 flex flex-col justify-start items-center space-y-6"
      style={{
        backgroundColor: '#ffffff',
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%235a380a' fill-opacity='0.025'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      }}
    >
      <motion.div
        className="flex flex-col items-center gap-6 md:max-w-5xl text-[#6c3b17]"
        variants={messageVariants}
        initial="initial"
        animate="animate"
      >
        {/* Thank you for your order */}
        <motion.h1
          className="text-4xl font-bold text-center"
          variants={messageVariants}
        >
          {message}
        </motion.h1>

        {/* preparing food text */}
        <motion.p
          className="text-lg text-center w-[50%] font-semibold"
          variants={dotsVariants}
          animate="animate"
        >
          We are preparing your food.
        </motion.p>

        {/* return home button */}
        <motion.div>
          <Link
            to="/"
            className="btn hover:border-none hover:bg-white border-[#6c3b17] border-[1px] bg-white text-[#6c3b17]"
            onClick={() => props.cleartContext()}
          >
            Return to Home
          </Link>
        </motion.div>

        {/* display cart */}
        <motion.div>
          {/* display cart items */}
          <p className="text-center text-2xl font-semibold">Your Order</p>
          {props.cart &&
            props.cart.map((item, index) => (
              <PaidItem food={item} index={index} key={index} />
            ))}
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
