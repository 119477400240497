import { useEffect, useState } from 'react';
import CartTab from '../components/CartTab';
import CategoryTabBar from '../components/CategoryTabBar';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import MenuItems from '../components/MenuItems';
import Navbar from '../components/Navbar';
import { useGetScrollPosition } from '../hooks/useGetScrollPosition';
import { updateFoodItem } from '../api/MenuCRUDService';

export default function Home() {
  const [currentTab, setCurrentTab] = useState<number>(4);
  const [loading, setLoading] = useState<boolean>(true);
  const waitTime = 500;

  const scrollPos = useGetScrollPosition();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, waitTime);
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, waitTime);
  }, [currentTab]);

  return (
    <div
      className="bg-[#fefefe] min-h-screen pb-2 lg:flex lg:justify-center"
      style={{
        backgroundColor: '#ffffff',
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%235a380a' fill-opacity='0.025'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      }}
    >
      <div className="flex flex-col justify-center md:max-w-5xl">
        {/* navbar */}
        <Navbar />

        {/* popular items */}
        {/* <ItemShowcase /> */}

        {/* category tab bar */}
        <div className="sticky top-2 z-40">
          <CategoryTabBar setCurrentTab={setCurrentTab} scrollPos={scrollPos} />
        </div>

        {/* menu items */}
        {loading ? <Loading /> : <MenuItems currentTab={currentTab} />}

        {/* footer */}
        <Footer />

        {/* checkout tab */}
        <CartTab />
      </div>
    </div>
  );
}
