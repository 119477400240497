import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../App';
import { Food } from '../interfaces/Food';

interface Props {
  totalSugars: number;
  setTotalSugars: Function;
  item: Food;
}

const Counter = (props: Props) => {
  // context
  const { tmpFood, setTmpFood } = useContext(AppContext);


  const  [_tmpFood, set_tmpFood] = useState<Food>(tmpFood || {});
  const [count, setCount] = useState(
    tmpFood?.customization?.findIndex(
      (item) => item.name === props.item.name
    ) !== -1
      ? tmpFood?.customization?.find((item) => item.name === props.item.name)
          ?.quantity || 1
      : 0
  );
  const [isDisabled, setDisabled] = useState<boolean>(false);

  const handleIncrement = () => {
    if (props.totalSugars < 8) {
      setCount(count + 1);
      props.setTotalSugars(props.totalSugars + 1);

      // find in customization, if exist, increment quantity, else add to customization
      const index = tmpFood?.customization?.findIndex(
        (item) => item.name === props.item.name
      );
      if (index !== -1) {
        const _tmpFood = {
          ...tmpFood,
          customization: tmpFood?.customization?.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                quantity: item.quantity ? item.quantity + 1 : 2,
              };
            }
            return item;
          }),
          sugars: tmpFood?.sugars?.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                quantity: item.quantity ? item.quantity + 1 : 2,
              };
            }
            return item;
          }),
        };
        setTmpFood!(_tmpFood);
      } else {
        const _tmpFood = {
          ...tmpFood,
          customization: [...tmpFood?.customization!, props.item],
        };
        set_tmpFood!(_tmpFood);
      }
    }
  };

  const handleDecrement = () => {
    if (count > 0 || props.item.quantity ? props.item!.quantity! > 0 : false) {
      setCount(count - 1);
      props.setTotalSugars(
        props.totalSugars - 1 < 0 ? 0 : props.totalSugars - 1
      );
      // find in customization, if exist, decrement quantity, else add to customization
      const index = tmpFood?.customization?.findIndex(
        (item) => item.name === props.item.name
      );
      if (index !== -1) {
        const _tmpFood = {
          ...tmpFood,
          customization: tmpFood?.customization?.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                quantity: item.quantity ? item.quantity - 1 : 0,
              };
            }
            return item;
          }),
          sugars: tmpFood?.sugars?.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                quantity: item.quantity ? item.quantity - 1 : 0,
              };
            }
            return item;
          }),
        };
        setTmpFood!(_tmpFood);
      } else {
        const _tmpFood = {
          ...tmpFood,
          customization: tmpFood?.customization?.filter(
            (item) => item.name !== props.item.name
          ),
        };
        set_tmpFood!(_tmpFood);
      }
    }
  };
  
  // when _tmpFood changes, update tmpFood
  useEffect(() => {
    setTmpFood!(_tmpFood);  
  }, [_tmpFood, setTmpFood]);

  return (
    <div className="flex items-center justify-center text-slate-700">
      <button
        onClick={() => {
          setDisabled(true);
          handleDecrement();
          setTimeout(() => {
            setDisabled(false);
          }, 200);
        }}
        className="bg-transparent text-[#6c3b17] font-semibold hover:scale-90 px-1 border border-[#6c3b17] rounded"
        disabled={isDisabled}
      >
        ←
      </button>
      <div
        className="mx-4 font-bold text-xl"
        onChange={(event) => {
          // onChange update customization with sugar quantity
          const index = tmpFood?.customization?.findIndex(
            (item) => item.name === props.item.name
          );

          if (index !== -1) {
            // add the item into the customization array
            const _tmpFood = {
              ...tmpFood,
              customization: tmpFood?.customization?.map((item, i) => {
                if (i === index) {
                  return {
                    ...item,
                    quantity: parseInt('1'),
                  };
                }
                return item;
              }),
            };
          }
        }}
      >
        {props.item.quantity && props.item.quantity > 0
          ? props.item.quantity
          : count}
      </div>
      <button
        onClick={() => {
          setDisabled(true);
          handleIncrement();
          setTimeout(() => {
            setDisabled(false);
          }, 200);
        }}
        className="bg-transparent text-[#6c3b17] font-semibold hover:scale-90 px-1 border border-[#6c3b17] rounded"
        disabled={isDisabled}
      >
        →
      </button>
    </div>
  );
};

export default Counter;
