import Item from './Item';
import { menuData } from '../data/foodData';

interface Props {
  currentTab: number;
}

export default function MenuItems(props: Props) {
  return (
    <>
      <div className="pt-5 grid grid-cols-1 md:grid-cols-2 gap-5 px-5">
        {menuData[props.currentTab].menu!.map((item, index) => {
          return <Item food={item} key={index} />;
        })}
      </div>
    </>
  );
}
