import { Food } from '../interfaces/Food';

export async function updateFoodItem(
  foodType: string,
  itemId: string,
  updatedItem: Food
) {
  const endpointUrl = `https://r1isfpy1tg.execute-api.us-east-1.amazonaws.com/prod/menu?id=${itemId}&type=${foodType}`;

  try {
    const response = await fetch(endpointUrl, {
      method: 'PATCH',
      body: JSON.stringify(removeIdAndType(updatedItem)),
    });
    if (!response.ok) {
      throw new Error(`Failed to update ${foodType} item.`);
    }
    const data = await response.json();
    console.log(`${foodType} item updated successfully:`, data);
  } catch (error) {
    console.error(`Error updating ${foodType} item:`, error);
  }
}

// create a function for removing id and type from a Food item
const removeIdAndType = (foodItem: Food) => {
  const { id, type, ...rest } = foodItem;
  return rest;
};
